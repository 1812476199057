import React, { useEffect, useState } from "react";
import Axios from "axios";
import CurrencyFormat from "../../components/CurrencyFormat";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";
import AlertMessage from "../../components/AlertMessage";
import { openSaleTicket } from "../../components/Ticket";
import MenuCategoryItem from "../../components/MenuCategoryItem";

function PointOfSale() {
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  //const [updateProductPrice, setUpdateProductPrice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [frequentCustomer, setFrequentCustomer] = useState(0);
  const [temporaryCustomer, setTemporaryCustomer] = useState("No especificado");
  const [customerOption, setCustomerOption] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [companySlug, setCompanySlug] = useState();
  const [moneyReceived, setMoneyReceived] = useState(0);
  const [change, setChange] = useState(0);
  const [status, setStatus] = useState(3);
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [orderDate, setOrderDate] = useState();
  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  //URL used to make request to the API
  const httpUrl = configUrl.uri;

  const fetchProducts = async (userId) => {
    setIsLoading(true);
    const res = await Axios.get(
      httpUrl + `/products/fetch-pos-products/${userId}`
    );
    setProducts(await res.data);
    setAllProducts(await res.data);
    //console.log("Products", res);
    setIsLoading(false);
  };

  const fetchCategories = async (compId) => {
    const res = await Axios.get(httpUrl + `/categories/${compId}`);
    //console.log("Categories", res.data);
    setCategories(await res.data);
  };

  const filterProductsByCategory = (categoryId) => {
    if (categoryId) {
      const currentItems = allProducts.filter(
        (item) => parseInt(item.category_id) === parseInt(categoryId)
      );
      setProducts(currentItems);
    } else {
      setProducts(allProducts);
    }
  };

  const textStyles = {
    fontSize: "0.90rem",
  };

  const productItemStyles = {
    minHeight: "220px",
  };

  const activateCustomerOption = async (number) => {
    setCustomerOption(number);
  };

  const fetchCustomers = async () => {
    const res = await Axios.get(httpUrl + `/customers/${userId}`);
    setCustomers(await res.data);
  };

  const getCompanyUser = async (userId) => {
    const res = await Axios.get(
      httpUrl + `/users/get-company-user-data/${userId}`
    );
    //console.log("companyUser", res);
    setCompanyId(await res.data.company_id);
    setBranchId(await res.data.branch_id);
    setCompanySlug(await res.data.company.slug);
    fetchCategories(await res.data.company_id);
  };

  const addExistingCustomer = async (e) => {
    setFrequentCustomer(e.target.value);
  };

  const addTemporaryCustomer = async (e) => {
    setTemporaryCustomer(e.target.value);
    //console.log(e.target.value);
  };

  const addPaymentMethod = async (e) => {
    setPaymentMethod(e.target.value);
  };

  const addStatus = async (e) => {
    //Here we used a parseInt Function to always have an int value,
    //We fixed an issue with the pending status validation to always have
    //the same datatype on the status variable and the validation,
    //These changes were made for the confirm button we have on the
    // confirm modal.
    setStatus(parseInt(e.target.value));
  };

  const addProductToCart = async (product) => {
    let maxQuantity = product.inventory[0].quantity;

    //console.log("Card Quantity:", maxQuantity);

    let productVariant;
    let currentSize;
    let sizeId;
    let sizeName;

    if (product.variants.length > 0) {
      productVariant = document.querySelector(
        `.product-${product.id}-variants`
      );

      currentSize = productVariant.querySelector(".active-size");
      sizeId = parseInt(currentSize.getAttribute("sizeid"));
      sizeName = currentSize.getAttribute("sizename");
    } else {
      sizeId = null;
    }

    //Check if The Added product exist.
    let findProductInCart = await cart.find((i) => {
      return i.size_id
        ? i.id === product.id && i.size_id === sizeId
        : i.id === product.id;
    });

    if (findProductInCart) {
      let newCart = [];
      let newItem;

      cart.forEach((cartItem) => {
        if (cartItem.id === product.id && cartItem.size_id === sizeId) {
          let currentQuantity = cartItem.quantity + 1;
          let currentAddButton = document.querySelector(
            "#addProduct" + cartItem.id
          );
          if (currentQuantity >= maxQuantity) {
            currentAddButton.disabled = true;
          } else {
            currentAddButton.disabled = false;
          }

          newItem = {
            ...cartItem,
            quantity: cartItem.quantity + 1,
            priceToPay: cartItem.price * (cartItem.quantity + 1),
          };

          newCart.push(newItem);
          console.log(newItem);
        } else {
          newCart.push(cartItem);
          console.log(cartItem);
        }
      });

      setCart(newCart);
    } else {
      let addingProduct = {
        ...product,
        size_id: sizeId !== "" || sizeId !== null ? sizeId : "",
        abbreviation: sizeName ? sizeName : "",
        quantity: 1,
        maxQuantity: maxQuantity,
        priceToPay:
          product.price === 0 || product.price === "" || product.price === null
            ? product.variants[0].price
            : product.price,
      };

      //console.log(addingProduct);
      setCart([...cart, addingProduct]);
    }

    console.log("Cart: ", cart);
  };

  const removeProduct = async (product) => {
    const newCart = cart.filter(
      (cartItem) =>
        cartItem.size_id !== product.size_id || cartItem.id !== product.id
    );
    setCart(newCart);
  };

  const changeQuantity = async (product, newQuantity) => {
    const updatingCart = cart.map((cartItem) => {
      let currentAddButton = document.querySelector(
        "#addProduct" + cartItem.id
      );
      if (cartItem.id === product.id) {
        if (newQuantity < product.inventory[0].quantity) {
          return {
            ...cartItem,
            quantity: parseFloat(newQuantity),
            priceToPay: cartItem.price * parseFloat(newQuantity),
          };
        } else {
          currentAddButton.disabled = true;
          return {
            ...cartItem,
            quantity: parseFloat(product.inventory[0].quantity),
            priceToPay:
              cartItem.price * parseFloat(product.inventory[0].quantity),
          };
        }
      }
      return cartItem;
    });
    setCart(updatingCart);
    //console.log("Cart Updated" + updatingCart);
  };

  const moneyOperation = async (moneyReceived) => {
    setMoneyReceived(moneyReceived);

    if (!moneyReceived <= 0) {
      const change = moneyReceived - totalAmount;
      setChange(change);
    }
  };

  const paySale = async (e) => {
    const apiUrl = httpUrl + "/sales/save-point-of-sale-data";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    document.querySelector("#confirmGenerateAndPayOrderBtn").disabled = true;

    if (cart.length === 0) {
      alert("Porfavor agrega un producto para poder concretar la compra.");
    } else {
      let data = new FormData();

      const saleProducts = JSON.stringify(cart);

      data.append("temporary_customer", temporaryCustomer);
      data.append("customer_id", frequentCustomer);
      data.append("total_amount", totalAmount);
      data.append("money_received", moneyReceived);
      data.append("change", change);
      data.append("products", saleProducts);
      data.append("company_id", companyId);
      data.append("branch_id", branchId);
      data.append("status", status);
      data.append("payment_method", paymentMethod);
      data.append("user_id", userId);
      //console.log(saleProducts);

      // Sending data to the api
      Axios.post(apiUrl, data, config)
        .then(function (res) {
          console.log("New Sale Data: ", res.data);
          if (res["status"] === 200) {
            alert("La venta ha sido procesada.");

            //Setting variables to the default value.
            setTemporaryCustomer("No especificado");
            setFrequentCustomer(0);
            setTotalAmount(0);
            setMoneyReceived(0);
            setChange(0);
            setCart([]);
            setCompanyId(0);
            setBranchId(0);
            setStatus(3);
            setPaymentMethod(1);

            //Executing functions to load the necessary data on the Screen.
            currentDate();
            getData();

            //Execute function to download the ticket.
            openSaleTicket(res.data.saleId);
          }
        })
        .catch(function (error) {
          //console.log(error);
          alert("Hubo un problema al intentar procesar la solicitud.");
        });
    }
    e.preventDefault();
  };

  const currentDate = async () => {
    var date = new Date();
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    setOrderDate(date.toLocaleDateString("es-ES", options));
  };

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);
    getCompanyUser(await uId);
    fetchProducts(await uId);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    currentDate();
  });

  useEffect(() => {
    let newTotalAmount = 0;
    cart.forEach((icart) => {
      newTotalAmount = newTotalAmount + parseFloat(icart.priceToPay);
    });
    setTotalAmount(newTotalAmount);
  }, [cart]);

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const activateSize = (e, productId, newPrice) => {
    //console.log(productId);
    //Get current card element
    const currentCard = document.querySelector(
      `.product-${productId}-variants`
    );

    //Get sizes buttons that belongs to the current card
    const sizeButtons = currentCard.querySelectorAll(".size-btn");

    //Iterating buttons to remove active class
    sizeButtons.forEach((btn) => {
      btn.classList.remove("active-size");
    });

    //activating current button.
    e.target.classList.add("active-size");

    const updatedProducts = products.map((product) => {
      if (parseInt(product.id) === parseInt(productId)) {
        return { ...product, price: parseFloat(newPrice) };
      }
      return product;
    });
    //console.log("Updated Products:", updatedProducts);
    setProducts(updatedProducts);

    e.preventDefault();
  };

  return (
    <div>
      {can("point_of_sale") ? (
        <div className="row mt-4 mb-5">
          <div className="col-12 col-sm-6 col-xl-7 order-2 order-sm-1">
            {isLoading ? (
              <AlertMessage type="primary" message="Cargando..." />
            ) : (
              <div className="row">
                <div className="col-12">
                  <h2 className="text-start mb-3">
                    <strong>Categorias</strong>{" "}
                    <span className="fw-light">del Menu</span>
                  </h2>
                </div>
                <div className="col-12">
                  {categories.length > 0 ? (
                    <div className="row">
                      <div className="col-6 col-sm-4 col-md-4 col-lg-6 col-xl-3 col-xxl-2">
                        <MenuCategoryItem
                          id={`0`}
                          name={"Todos"}
                          class="active"
                          function={() => filterProductsByCategory()}
                        ></MenuCategoryItem>
                      </div>
                      {categories.map(
                        (cat) =>
                          //Verifying if the category has product attached
                          allProducts.some(
                            (product) =>
                              parseInt(product.category_id) ===
                                parseInt(cat.id) && product.inventory.length > 0
                          ) && (
                            <div
                              className="col-6 col-sm-4 col-md-4 col-lg-6 col-xl-3 col-xxl-2"
                              key={cat.id}
                            >
                              <MenuCategoryItem
                                id={cat.id}
                                name={cat.name}
                                function={() =>
                                  filterProductsByCategory(parseInt(cat.id))
                                }
                              ></MenuCategoryItem>
                            </div>
                          )
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12">
                  <h2 className="text-start mb-3">
                    <strong>Escoge</strong>{" "}
                    <span className="fw-light">una Orden</span>
                  </h2>
                </div>
                {products.map((item, key) =>
                  item.inventory.length > 0 ? (
                    <div className="col-12 col-xl-6 col-xxl-4" key={key}>
                      <div
                        className={`card mb-3 shadow border-0 product-card-${item.id}`}
                      >
                        <div className="row g-0" style={productItemStyles}>
                          <div className="col-5">
                            <img
                              src={`${configUrl.baseUrl}/storage/companies/${companySlug}/products/images/${item.image}`}
                              className="w-100 h-100 rounded-start"
                              style={{
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                              alt={`${item.name}`}
                            />
                          </div>
                          <div className="col-7">
                            <div className="card-body px-2 px-xxl-3 h-100 d-flex justify-content-center align-items-center">
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-12 col-xl-7 col-xxl-12">
                                    <h6
                                      className="card-title text-start mb-2 fw-bolder"
                                      style={textStyles}
                                    >
                                      {item.name}
                                    </h6>
                                  </div>
                                  <div className="col-12 col-xl-5 col-xxl-12">
                                    <p className="card-text text-start text-muted mb-2">
                                      <CurrencyFormat
                                        price={
                                          item.price === 0 ||
                                          item.price === null ||
                                          item.price === "" ||
                                          (item.price === null &&
                                            item.variants.length > 0)
                                            ? (item.price = parseFloat(
                                                item.variants[0].price
                                              ))
                                            : parseFloat(item.price)
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>

                                {item.variants.length > 0 ? (
                                  <div>
                                    <small className="me-3 fw-bold">
                                      Tamaño:
                                    </small>
                                    <div
                                      className={`d-flex flex-wrap mt-1 mb-2 product-${item.id}-variants`}
                                    >
                                      {item.variants.map((variant, key) => (
                                        <button
                                          key={key}
                                          dataproductid={variant.product_id}
                                          sizeid={variant.size_id}
                                          sizename={variant.size.abbreviation}
                                          sizeprice={variant.price}
                                          onClick={(e) =>
                                            activateSize(
                                              e,
                                              variant.product_id,
                                              variant.price
                                            )
                                          }
                                          className={`btn btn-sm btn-outline-dark rounded-pill mb-1 me-1 size-btn ${
                                            key === 0 ? "active-size" : ""
                                          }`}
                                        >
                                          {variant.size.abbreviation}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <button
                                  id={"addProduct" + item.id}
                                  className="btn btn-sm btn-outline-danger rounded-0 w-100 rounded-pill"
                                  onClick={() => addProductToCart(item)}
                                  disabled={
                                    item.inventory[0].quantity === 0
                                      ? true
                                      : false
                                  }
                                >
                                  Agregar <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            )}
          </div>

          {/*Cart*/}
          <div className="col-12 col-sm-6 col-xl-5 order-1 order-sm-2">
            <form className="card shadow mb-4 ">
              <div className="card-header bg-danger p-3">
                <h5 className="card-title text-white text-start mb-0">
                  Nueva Orden
                </h5>
                <p className="card-text text-start text-capitalize">
                  <small className="text-white">{orderDate}</small>
                </p>
              </div>
              <div className="card-body bg-light">
                <div className="row mb-3">
                  <div className="col-6 text-center">
                    <button
                      type="button"
                      className={
                        "btn btn-outline-primary btn-sm " +
                        (customerOption === 1 ? "bg-primary text-white" : "")
                      }
                      onClick={() => {
                        fetchCustomers();
                        activateCustomerOption(1);
                      }}
                    >
                      Cliente Existente
                    </button>
                  </div>
                  <div className="col-6 text-center">
                    <button
                      type="button"
                      className={
                        "btn btn-outline-primary btn-sm " +
                        (customerOption === 2 ? "bg-primary text-white" : "")
                      }
                      onClick={() => activateCustomerOption(2)}
                    >
                      Cliente provisional
                    </button>
                  </div>
                </div>
                {customerOption === 1 ? (
                  <div className="row mb-3">
                    <div className="col-12">
                      <select
                        className="form-select"
                        name="customer_id"
                        id="customer_id"
                        onChange={(e) => addExistingCustomer(e)}
                      >
                        <option value="" disabled>
                          Selecciona una opción
                        </option>
                        <option value="0">No agregar</option>
                        {customers.map((customer, key) => (
                          <option value={customer.id} key={key}>
                            {customer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {customerOption === 2 ? (
                  <div className="row mb-3">
                    <div className="col-12">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nombre del cliente"
                        className="form-control mb-3"
                        onChange={(e) => addTemporaryCustomer(e)}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {cart.length === 0 ? (
                  <div className="alert alert-primary text-center" role="alert">
                    <small className="card-text">Agregue un producto.</small>
                  </div>
                ) : (
                  cart.map((product, key) => (
                    <div className="row" key={key}>
                      <div className="col-2 col-xl-auto my-auto">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger text-center"
                          onClick={() => removeProduct(product)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                      <div className="col-6 col-md-5 col-xl-4 my-auto">
                        <p className="text-start mb-0">
                          <small>{product.name}</small>
                        </p>
                        <p className="text-start text-muted mb-0">
                          <small>
                            <b>{product.abbreviation}</b>
                            {product.size_id ? " - " : ""}
                            <CurrencyFormat price={product.price} />
                          </small>
                        </p>
                      </div>
                      <div className="col-4 col-md-5 col-xl-6 my-auto">
                        <div className="row">
                          <div className="col-12 col-xl-6 text-end my-auto">
                            <p className="mb-0 text-start text-end pe-2">
                              <small>{product.unit.name}</small>
                            </p>
                            <input
                              type="number"
                              className="form-control form-control-sm border-danger me-1 ms-auto text-end"
                              style={{ maxWidth: "100px" }}
                              min="0.01"
                              step="0.01"
                              name="quantity"
                              id={"cartItemQuantity" + product.id}
                              value={product.quantity}
                              disabled={
                                product.quantity >=
                                product.inventory[0].quantity
                                  ? true
                                  : ""
                              }
                              onChange={(e) =>
                                changeQuantity(product, e.target.value)
                              }
                            />
                          </div>
                          <div className="col-12 col-xl-6 text-end my-auto">
                            <p className="mb-0 pe-2">
                              <small>
                                <CurrencyFormat price={product.priceToPay} />
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="card-footer bg-white">
                <div className="row mb-3">
                  <div className="col-6 my-auto">
                    <p className="card-text text-start">
                      <small className="text-muted">Monto Total:</small>
                    </p>
                  </div>
                  <div className="col-6 my-auto">
                    <p className="card-text text-end pe-2">
                      <small className="text-muted">
                        <CurrencyFormat price={totalAmount} />
                      </small>
                    </p>
                  </div>
                </div>
                <div className="row mb-3 justify-content-end">
                  <div className="col-5 my-auto">
                    {/* <button
                      type="button"
                      className="btn btn-sm btn-outline-danger w-100 py-2 d-flex justify-content-center align-items-center"
                      disabled={cart.length === 0 ? true : ""}
                      data-bs-toggle="modal"
                      data-bs-target="#generatePendingOrderModal"
                    >
                      <i className="far fa-save mx-auto ps-2"></i> Generar orden
                      pendiente
                    </button> */}
                  </div>
                  <div className="col-7 my-auto">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary w-100 py-2"
                      id="generateAndPayOrderBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#confirmSale"
                      disabled={cart.length === 0 ? true : ""}
                    >
                      <i className="fas fa-money-bill me-3"></i> Generar y Pagar
                      ahora
                    </button>
                  </div>
                </div>
              </div>

              {/* Modal: Confirm Generate Pending Order  
              
              <div
                className="modal fade"
                id="generatePendingOrderModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="generatePendingOrderLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="generatePendingOrderLabel"
                      >
                        Atención!
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          <h6>¿Desea generar una orden pendiente?</h6>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="row w-100">
                        <div className="col-6 text-start">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            No, Cancelar
                          </button>
                        </div>
                        <div className="col-6 text-end">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) => paySale(e)}
                            disabled={
                              moneyReceived > 0 && moneyReceived >= totalAmount
                                ? ""
                                : true
                            }
                          >
                            Si, Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              */}

              {/* Modal: Confirm Generate and Pay Sale  */}
              <div
                className="modal fade"
                id="confirmSale"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="confirmSaleLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="confirmSaleLabel">
                        Atención!
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="row">
                            <label
                              htmlFor="moneyReceived"
                              className="col-form-label col-4 text-start"
                            >
                              Monto Total:
                            </label>
                            <div className="col-8">
                              <p className="mb-0 text-end pe-2 fw-bold text-success">
                                <CurrencyFormat price={totalAmount} />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <label
                              htmlFor="moneyReceived"
                              className="col-form-label col-5 text-start"
                            >
                              Dinero Recibido:
                            </label>
                            <div className="col-7">
                              <input
                                type="number"
                                name="moneyReceived"
                                id="moneyReceived"
                                min="0.01"
                                step="0.01"
                                placeholder=""
                                className="form-control mb-3 text-end"
                                onChange={(e) => moneyOperation(e.target.value)}
                                value={moneyReceived}
                              />
                            </div>
                            {moneyReceived < totalAmount ? (
                              <div className="col-12">
                                <p className="text-danger text-center">
                                  <small>
                                    El dinero recibido debe ser igual o mayor al
                                    monto total.
                                  </small>
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <label
                              htmlFor="change"
                              className="col-form-label col-4 text-start"
                            >
                              Cambio:
                            </label>
                            <div className="col-8">
                              <p className="mb-0 text-end pe-2 fw-bold text-primary">
                                <CurrencyFormat price={change} />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row mb-3">
                            <label
                              htmlFor="paymentMethod"
                              className="col-form-label col-5 text-start"
                            >
                              Método de Pago:
                            </label>
                            <div className="col-7">
                              <select
                                className="form-select"
                                name="paymentMethod"
                                id="paymentMethod"
                                onChange={(e) => addPaymentMethod(e)}
                                value={paymentMethod}
                              >
                                <option value="0">No especificado</option>
                                <option value="1">Efectivo</option>
                                <option value="2">
                                  Transferencia electrónica
                                </option>
                                <option value="3">Tarjeta de Débito</option>
                                <option value="4">Tarjeta de Crédito</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <label
                              htmlFor="status"
                              className="col-form-label col-5 text-start"
                            >
                              Estado de Orden:
                            </label>
                            <div className="col-7">
                              <select
                                className="form-select"
                                name="status"
                                id="status"
                                onChange={(e) => addStatus(e)}
                                value={status}
                              >
                                <option value="0" disabled>
                                  Selecciona una opcion
                                </option>
                                <option value="0">No especificado</option>
                                <option value="1">Pendiente</option>
                                <option value="2">En proceso</option>
                                <option value="3">Completado</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="row w-100">
                        <div className="col-12">
                          <p className="fw-bold text-center">
                            ¿Desea proceder con la compra?
                          </p>
                        </div>
                        <div className="col-6 text-start">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            No, Cancelar
                          </button>
                        </div>
                        <div className="col-6 text-end">
                          <button
                            type="button"
                            className="btn btn-success"
                            data-bs-dismiss="modal"
                            id="confirmGenerateAndPayOrderBtn"
                            onClick={(e) => paySale(e)}
                            disabled={
                              status === 1 ||
                              (moneyReceived > 0 &&
                                moneyReceived >= totalAmount)
                                ? ""
                                : true
                            }
                          >
                            Si, Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="row my-5">
          <ContentDeniedMessage />
        </div>
      )}
    </div>
  );
}

export default PointOfSale;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import config from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

function NewInventory() {
  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [productId, setProductId] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const httpUrl = config.uri;

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);

    const apiUrl = httpUrl + `/inventory/create/${uId}`;

    Axios.get(apiUrl).then((res) => {
      console.log("Create Inventory record data: ", res);
      setBranches(res.data.branches);
      setProducts(res.data.products);
      setCompanyId(res.data.companyUser.company_id);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const saveRecord = async (e) => {
    const apiUrl = httpUrl + "/inventory/save";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let data = new FormData();
    data.append("quantity", quantity);
    data.append("product_id", productId);
    data.append("company_id", companyId);
    data.append("branch_id", branchId);

    /*const userdata = {
      name: name,
      email: email,
      password: password,
      company_id: company.company_id,
      branch_id: branchId,
      role_id: roleId,
    };
    console.log(userdata);*/

    //Sending data to the api to save the record
    Axios.post(apiUrl, data, config)
      .then(function (res) {
        console.log(res);
        if (res["data"]["status"] === 200) {
          alert("Registro guardado exitosamente.");
          /*setTimeout(() => {
            window.location.href = "/inventory";
          }, 2000);*/
        }
        if (res["data"]["status"] === 404) {
          alert(res["data"]["message"]);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });
  };

  return (
    <div className="card my-3">
      <div className="card-header">
        <h3 className="text-start">Nuevo Registro de Inventario</h3>
      </div>
      <div className="card-body">
        {can("add_inventories") ? (
          <form className="row justify-content-center rounded py-5 px-3">
            <p className="text-dark mb-3 text-center">
              Para crear un nuevo registro por favor rellena los siguientes
              campos.
            </p>
            <div className="col-12 col-md-5 col-xl-4 text-start mb-3">
              <label htmlFor="product_id" className="form-label">
                Producto:
              </label>
              <select
                className="form-select"
                name="product_id"
                id="product_id"
                onChange={(e) => setProductId(parseInt(e.target.value))}
                value={productId}
              >
                <option value="0">Selecciona una opción</option>
                {products.map((item, key) => (
                  <option value={item.id} key={key}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-5 col-xl-4 text-start mb-3">
              <label htmlFor="branch_id" className="form-label">
                Sucursal:
              </label>
              <select
                className="form-select"
                name="branch_id"
                id="branch_id"
                onChange={(e) => setBranchId(parseInt(e.target.value))}
                value={branchId}
              >
                <option value="0">Selecciona una opción</option>
                {branches.map((item, key) => (
                  <option value={item.id} key={key}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 col-md-2 col-xl-4 text-start mb-3">
              <label htmlFor="quantity" className="form-label">
                Cantidad:
              </label>
              <input
                type="number"
                name="quantity"
                id="quantity"
                min="0.01"
                step="0.01"
                placeholder="Ingrese un número"
                className="form-control mb-3"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
              />
            </div>

            <div className="col-6">
              <Link
                to="/inventory"
                className="btn btn-outline-secondary rounded-0"
              >
                Cancelar
              </Link>
            </div>
            <div className="col-6">
              <button
                className="btn btn-outline-success rounded-0"
                type="button"
                onClick={(e) => saveRecord(e)}
              >
                Guardar
              </button>
            </div>
          </form>
        ) : (
          <ContentDeniedMessage />
        )}
      </div>
    </div>
  );
}

export default NewInventory;

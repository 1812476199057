import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link, useParams } from "react-router-dom";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";
import AlertMessage from "../../components/AlertMessage";
import ExpenseCategoryList from "./ExpenseCategories/ExpenseCategoryList";
import { Doughnut, Line } from "react-chartjs-2";
import CurrencyFormat from "../../components/CurrencyFormat";

const FinanceIndex = () => {
  //Default data
  const [userId, setUserId] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const httpUrl = configUrl.uri;

  //Records to be displayed on each select field.
  const [branches, setBranches] = useState([]);

  //General Variables
  const [generalStartDate, setGeneralStartDate] = useState("");
  const [generalEndDate, setGeneralEndDate] = useState("");
  const [generalBranch, setGeneralBranch] = useState(0);

  //***** GENERAL SUMMARY *****
  //General Summary Variables
  const [summaryStartDate, setSummaryStartDate] = useState("");
  const [summaryEndDate, setSummaryEndDate] = useState("");
  const [summaryBranch, setSummaryBranch] = useState(0);

  //Data
  const [generalIncome, setGeneralIncome] = useState(0);
  const [generalExpense, setGeneralExpense] = useState(0);
  const [generalRevenue, setGeneralRevenue] = useState(0);

  const [summaryData, setSummaryData] = useState({
    labels: ["Ingresos", "Gastos"],
    datasets: [
      {
        label: "Cantidad",
        data: [generalIncome, generalExpense],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  });

  //***** EXPENSES *****
  //Expense's variables
  const [expenseStartDate, setExpenseStartDate] = useState("");
  const [expenseEndDate, setExpenseEndDate] = useState("");
  const [expenseBranch, setExpenseBranch] = useState(0);

  const [expenseDays, setExpenseDays] = useState([]);
  const [expenseAmounts, setExpenseAmounts] = useState([]);
  const [expenseData, setExpenseData] = useState("");

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const companyId = parseInt(localStorage.getItem("companyId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setCompanyId(await companyId);
    setPermissions(await currentPermissions);

    const res = await Axios.get(httpUrl + `/finance/${companyId}`);
    setBranches(res.data.branches);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const summaryRevenueLabelStyles = {
    position: "absolute",
    zIndex: 10,
    marginTop: "4%",
  };

  const setGeneralData = async () => {
    //Updating Summary Variables
    const newSummaryStartDate = await generalStartDate;
    const newSummaryEndDate = await generalEndDate;
    const newSummaryBranch = await generalBranch;

    setSummaryStartDate(newSummaryStartDate);
    setSummaryEndDate(newSummaryEndDate);
    setSummaryBranch(newSummaryBranch);

    //Updating Expense Variables
    const newExpenseStartDate = await generalStartDate;
    const newExpenseEndDate = await generalEndDate;
    const newExpenseBranch = await generalBranch;

    setExpenseStartDate(newExpenseStartDate);
    setExpenseEndDate(newExpenseEndDate);
    setExpenseBranch(newExpenseBranch);

    getSummaryData(newSummaryStartDate, newSummaryEndDate, newSummaryBranch);
    getExpenseData(newExpenseStartDate, newExpenseEndDate, newExpenseBranch);
  };

  const getSummaryData = async (newSummaryStartDate = null, newSummaryEndDate = null, newSummaryBranch = null) => {
    const httpUrl = configUrl.uri;

    let data = new FormData();
    data.append("companyId", localStorage.getItem("companyId"));
    data.append("branchId", newSummaryBranch ? newSummaryBranch : summaryBranch);
    data.append("startDate", newSummaryStartDate ? newSummaryStartDate : summaryStartDate);
    data.append("endDate", newSummaryEndDate ? newSummaryEndDate : summaryEndDate);

    const res = await Axios.post(`${httpUrl}/finance/get-summary-data`, data);

    setGeneralIncome(await res.data.income);
    setGeneralExpense(await res.data.expense);
    let revenue = res.data.income - res.data.expense;
    setGeneralRevenue(revenue);

    //setSummaryData(await);

    console.log("Finance Income:", res);
  };

  const getExpenseData = async (newExpenseStartDate = null, newExpenseEndDate = null, newExpenseBranch = null) => {
    const httpUrl = configUrl.uri;

    let data = new FormData();

    data.append("companyId", localStorage.getItem("companyId"));
    data.append("branchId", newExpenseBranch ? newExpenseBranch : expenseBranch);
    data.append("startDate", newExpenseStartDate ? newExpenseStartDate : expenseStartDate);
    data.append("endDate", newExpenseEndDate ? newExpenseEndDate : expenseEndDate);

    const res = await Axios.post(`${httpUrl}/finance/get-expense-data`, data);

    const days = await res.data.days;
    const amounts = await res.data.newExpenses;

    setExpenseDays(days);
    setExpenseAmounts(amounts);

    const dataChart = {
      labels: days,
      datasets: [
        {
          label: "Gastos por fecha",
          data: amounts,
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
        },
      ],
    };

    setExpenseData(dataChart);

    console.log("Expense Data:", dataChart);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="my-3 px-3">
      {/* Header */}
      <div className="row mb-3">
        <div className="col-12 col-sm-6 my-auto">
          <h2 className="text-center text-sm-start mb-3 mb-sm-0">Finanzas</h2>
        </div>
        <div className="col-12 col-sm-6 my-auto text-center text-sm-end">
          <Link
            to="/finance/expenses"
            className="btn btn-outline-danger rounded-0 px-4 mb-3 mb-sm-0"
          >
            Ir a Gastos
          </Link>
        </div>
      </div>

      {/* General Finance Filters */}
      <div className="row mb-4">
        <div className="col-12">
          <p className="text-center">
            Mostrar ambos graficos con datos generales.
          </p>
        </div>
        <div className="col-12 col-sm-6 col-xl-4">
          <div className="row mb-3 mb-xl-0">
            <div className="col-12 col-md-6 col-xl-5 my-auto text-center text-md-start">
              <label htmlFor="generalStartDate" className="form-label mb-md-0">
                Fecha de Inicio:
              </label>
            </div>
            <div className="col-12 col-md-6 col-xl-7 my-auto">
              <input
                type="date"
                className="form-control"
                name="generalStartDate"
                id="generalStartDate"
                onChange={(e) => setGeneralStartDate(e.target.value)}
                value={generalStartDate}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-xl-4">
          <div className="row mb-3 mb-xl-0">
            <div className="col-12 col-md-6 col-xl-5 my-auto text-center text-md-start">
              <label htmlFor="generalEndDate" className="form-label mb-md-0">
                Fecha de Finalizacion:
              </label>
            </div>
            <div className="col-12 col-md-6 col-xl-7 my-auto">
              <input
                type="date"
                className="form-control"
                name="generalEndDate"
                id="generalEndDate"
                onChange={(e) => setGeneralEndDate(e.target.value)}
                value={generalEndDate}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-xl-4">
          <div className="row mb-3 mb-xl-0">
            <div className="col-12 col-md-6 col-xl-5 my-auto text-center text-md-start">
              <label htmlFor="generalBranch" className="form-label mb-md-0">
                Sucursal:
              </label>
            </div>
            <div className="col-12 col-md-6 col-xl-7 my-auto">
              <select
                className="form-select"
                name="generalBranch"
                id="generalBranch"
                onChange={(e) => {
                  setGeneralBranch(e.target.value);
                }}
                value={generalBranch}
              >
                <option value="0" disabled>
                  Selecciona una opción
                </option>
                {branches.map((branch, key) => (
                  <option value={branch.id} key={key}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-12 text-center">
          <button
            type=""
            className="btn btn-outline-primary rounded-0 mt-2 mt-md-4 px-4"
            onClick={() => setGeneralData()}
          >
            Consultar
          </button>
        </div>
      </div>

      {/* General Summary's Card */}
      <div className="row mb-4">
        <div className="col-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center">Resumen General</h3>
                </div>
                <div className="col-12 col-md-6 my-auto">
                  {generalIncome === 0 || generalExpense === 0 ? (
                    <AlertMessage
                      type="warning"
                      message="Aun no se ha consultado información."
                    />
                  ) : (
                    <div className="w-100 h-100 relative d-flex justify-content-center align-items-center">
                      <Doughnut
                        data={{
                          labels: ["Ingresos", "Gastos"],
                          datasets: [
                            {
                              label: "Total",
                              data: [generalIncome, generalExpense],
                              backgroundColor: [
                                "rgba(75, 192, 192, 0.2)",
                                "rgba(255, 99, 132, 0.2)",
                              ],
                              borderColor: [
                                "rgba(75, 192, 192, 1)",
                                "rgba(255, 99, 132, 1)",
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        className="relative"
                      />
                      <label
                        className="fs-4 fw-bold text-center"
                        style={summaryRevenueLabelStyles}
                      >
                        Ganancias: <br />
                        <CurrencyFormat price={generalRevenue} />
                      </label>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 my-auto">
                  <div className="row mb-3 mb-md-0">
                    <div className="col-12">
                      <p className="text-center">
                        Selecciona un rango de fechas
                      </p>
                    </div>
                    <div className="col-12 col-sm-6 my-auto text-center text-sm-start">
                      <label
                        htmlFor="summaryStartDate"
                        className="form-label mb-md-0"
                      >
                        Fecha de Inicio:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 my-auto">
                      <input
                        type="date"
                        className="form-control mb-3"
                        name="summaryStartDate"
                        id="summaryStartDate"
                        onChange={(e) => setSummaryStartDate(e.target.value)}
                        value={summaryStartDate}
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-auto text-center text-sm-start">
                      <label
                        htmlFor="summaryEndDate"
                        className="form-label mb-md-0"
                      >
                        Fecha de Finalizacion:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 my-auto">
                      <input
                        type="date"
                        className="form-control mb-3"
                        name="summaryEndDate"
                        id="summaryEndDate"
                        onChange={(e) => setSummaryEndDate(e.target.value)}
                        value={summaryEndDate}
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-auto text-center text-sm-start">
                      <label
                        htmlFor="summaryBranch"
                        className="form-label mb-md-0"
                      >
                        Sucursal:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 my-auto">
                      <select
                        className="form-select mb-3"
                        name="summaryBranch"
                        id="summaryBranch"
                        onChange={(e) => {
                          setSummaryBranch(e.target.value);
                        }}
                        value={summaryBranch}
                      >
                        <option value="0" disabled>
                          Selecciona una opción
                        </option>
                        {branches.map((branch, key) => (
                          <option value={branch.id} key={key}>
                            {branch.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type=""
                        className="btn btn-outline-primary rounded-0 mt-2 mt-md-4 px-4"
                        onClick={() => getSummaryData()}
                      >
                        Consultar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Expense's Card */}
      <div className="row mb-4">
        <div className="col-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center">Gastos</h3>
                </div>
                <div className="col-12 col-md-6 my-auto">
                  {expenseData ? (
                    <Line data={expenseData} />
                  ) : (
                    <AlertMessage
                      type="warning"
                      message="Aun no se ha consultado información."
                    />
                  )}
                </div>
                <div className="col-12 col-md-6 my-auto">
                  <div className="row mb-3 mb-md-0">
                    <div className="col-12">
                      <p className="text-center">
                        Selecciona un rango de fechas
                      </p>
                    </div>
                    <div className="col-12 col-sm-6 my-auto text-center text-sm-start">
                      <label
                        htmlFor="expenseStartDate"
                        className="form-label mb-md-0"
                      >
                        Fecha de Inicio:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 my-auto">
                      <input
                        type="date"
                        className="form-control mb-3"
                        name="expenseStartDate"
                        id="expenseStartDate"
                        onChange={(e) => setExpenseStartDate(e.target.value)}
                        value={expenseStartDate}
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-auto text-center text-sm-start">
                      <label
                        htmlFor="expenseEndDate"
                        className="form-label mb-md-0"
                      >
                        Fecha de Finalizacion:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 my-auto">
                      <input
                        type="date"
                        className="form-control mb-3"
                        name="expenseEndDate"
                        id="expenseEndDate"
                        onChange={(e) => setExpenseEndDate(e.target.value)}
                        value={expenseEndDate}
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-auto text-center text-sm-start">
                      <label
                        htmlFor="expenseBranch"
                        className="form-label mb-md-0"
                      >
                        Sucursal:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 my-auto">
                      <select
                        className="form-select mb-3"
                        name="expenseBranch"
                        id="expenseBranch"
                        onChange={(e) => {
                          setExpenseBranch(e.target.value);
                        }}
                        value={expenseBranch}
                      >
                        <option value="0" disabled>
                          Selecciona una opción
                        </option>
                        {branches.map((branch, key) => (
                          <option value={branch.id} key={key}>
                            {branch.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary rounded-0 mt-2 mt-md-4 px-4"
                        onClick={() => getExpenseData()}
                      >
                        Consultar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceIndex;

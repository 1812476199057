import React, { Component } from "react";

export default class Pagination extends Component {
  state = {
    currentPage: 1,
  };

  executeFunctions = (e, num) => {
    this.setState({ currentPage: num });
    this.props.paginate(num);
    //console.log(this.state.currentPage);
    e.preventDefault();
  };

  executePrevBtn = (e, num) => {
    this.setState({ currentPage: num - 1 });
    this.props.prevPage(num);
    //console.log(this.state.currentPage);
    e.preventDefault();
  };

  executeNextBtn = (e, num) => {
    this.setState({ currentPage: num + 1 });
    this.props.nextPage(num);
    //console.log(this.state.currentPage);
    e.preventDefault();
  };

  render() {
    const { itemsPerPage, totalItems, nextPage, prevPage } = this.props;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li
            className={
              "page-item " + (this.state.currentPage <= 1 ? "disabled" : "")
            }
          >
            <a
              className="page-link"
              href="#"
              onClick={(e) => this.executePrevBtn(e, this.state.currentPage)}
            >
              Previous
            </a>
          </li>
          {pageNumbers.map((num) => (
            <li
              className={
                "page-item " + (this.state.currentPage === num ? "active" : "")
              }
              key={num}
            >
              <a
                className="page-link"
                href="#"
                onClick={(e) => this.executeFunctions(e, num)}
              >
                {num}
              </a>
            </li>
          ))}
          <li
            className={
              "page-item " +
              (this.state.currentPage >= pageNumbers.length ? "disabled" : "")
            }
          >
            <a
              className="page-link"
              href="#"
              onClick={(e) => this.executeNextBtn(e, this.state.currentPage)}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

import React, { useState } from "react";
import Axios from "axios";
import bgImage from "../../assets/img/bg-image-min.jpg";
import config from "../../lib/config.json";
import user from "./User";

function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const loginImage = {
    backgroundImage: "url(" + bgImage + ")",
  };
  const [credentialError, setCredentialError] = useState(false);
  const [credentialErrorMessage, setCredentialErrorMessage] = useState("");
  //const [userData, setUserData] = useState([]);

  const http = Axios.create({
    baseURL: config.baseUrl,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });

  const signIn = async (e) => {
    http.get("/sanctum/csrf-cookie").then(() => {
      http
        .post("/api/login", { email: email, password: password })
        .then((res) => {
          if (res.data.errors) {
            setCredentialError(true);
            setCredentialErrorMessage(res.data.errors.message);
            console.log("Credential Errors", res.data.errors);
          } else {
            console.log("Login res", res.data);
            //setUserData(res.data);
            getUserData(parseInt(res.data.user.id), res.data);
          }
        });
    });

    e.preventDefault();
  };

  const getUserData = async (id, userData) => {
    const res = await http.get(`/api/users/permissions/${id}`);
    const companyUser = await http.get(
      `/api/users/get-company-user-info/${id}`
    );
    //console.log("Company User Info:", companyUser.data.companyUser);
    user.authenticated(await userData);
    user.setPermissions(await res.data);
    user.setCompanyData(await companyUser.data.companyUser);
    window.location = "/";
    //console.log("permissions", res.data);
  };

  const resetCredentialErrorMessage = () => {
    setCredentialError(false);
    setCredentialErrorMessage("");
  };

  return (
    <div className="container-fluid App">
      <div className="row size-auto bg-white">
        <div className="col-12 col-lg-6 order-2 order-lg-1 my-lg-auto">
          <div className="d-flex align-items-center justify-content-center py-5">
            <div className="content">
              <h6 className="text-center mb-3">BIENVENIDO</h6>
              <h4 className="text-center mb-4 text-danger fw-bold fs-1 text-uppercase">
                {"Login"}
              </h4>
              <p className="mb-4">
                Inicia sesión para comenzar a administrar tu negocio.
              </p>
              {credentialError === true ? (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {credentialErrorMessage}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => resetCredentialErrorMessage()}
                  ></button>
                </div>
              ) : (
                ""
              )}
              <form onSubmit={(e) => signIn(e)}>
                <label htmlFor="email" className="form-label">
                  Correo:
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="user@correo.com"
                  className="form-control mb-3"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <label htmlFor="password" className="form-label">
                  Contraseña:
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="**********"
                  className="form-control mb-5"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />

                <button className="btn btn-danger w-100 rounded-pill">
                  Iniciar sesión
                </button>
              </form>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-lg-6 bg-image-cover-center px-0 order-1 order-lg-2"
          style={loginImage}
        >
          <div className="bg-dark-red p-5 d-flex align-items-center justify-content-center">
            <div className="content">
              <h2 className="text-white text-center mb-3 text-uppercase fs-1">
                {config.name}
              </h2>
              <p className="text-white text-center mb-0">
                En Business Flow Mate, brindamos las opciones para llevar el
                control de tu negocio desde donde sea que estes, permitiendo ver
                el flujo de trabajo de los empleados y las estadisticas de tu
                negocio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

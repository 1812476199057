import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import config from "../../../lib/config.json";
import ContentDeniedMessage from "../../../components/ContentDeniedMessage";

export default function CreateProductVariant() {
  const httpUrl = config.uri;
  const { productId } = useParams();

  const [sizes, setSizes] = useState([]);
  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState(0);
  const [sizeId, setSizeId] = useState(0);

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);

    const apiUrl = httpUrl + "/product-variant/create";
    Axios.get(apiUrl).then((res) => {
      //console.log("Create Variant data: ", res);
      setSizes(res.data.sizes);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const saveRecord = async (e) => {
    const apiUrl = httpUrl + "/product-variant/save";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let data = new FormData();
    data.append("name", name);
    data.append("image", image);
    data.append("price", price);
    data.append("product_id", productId);
    data.append("size_id", sizeId);

    /*const userdata = {
      name: name,
      email: email,
      password: password,
      company_id: company.company_id,
      branch_id: branchId,
      role_id: roleId,
    };
    console.log(userdata);*/

    //Sending data to the api to save the record
    Axios.post(apiUrl, data, config)
      .then(function (res) {
        console.log(res);
        if (res["status"] === 200) {
          alert("Registro guardado exitosamente.");
          setTimeout(() => {
            window.location.href = `/products/edit/${productId}`;
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });
  };

  return (
    <div className="card my-3">
      <div className="card-header">
        <h3 className="text-start">Nueva Variante del Producto</h3>
      </div>
      <div className="card-body">
        {can("create_products") ? (
          <form className="row justify-content-center rounded py-5 px-3">
            <p className="text-dark mb-3 text-center">
              Para crear un nuevo registro por favor rellena los siguientes
              campos.
            </p>
            <div className="col-12 col-md-6 text-start mb-4 mb-md-0">
              <label htmlFor="name" className="form-label">
                Nombre:
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Nombre"
                className="form-control mb-3"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>

            <div className="col-12 col-md-6 text-start mb-4 mb-md-0">
              <label htmlFor="image" className="form-label">
                Imagen:
              </label>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  id="image"
                  lang="es"
                  accept="image/png, image/jpeg"
                  title="Selecciona una imagen"
                  onChange={(e) => setImage(e.target.files[0])}
                />
                <label htmlFor="image" className="input-group-text">
                  Examinar
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6 text-start mb-4">
              <label htmlFor="price" className="form-label">
                Precio:
              </label>
              <input
                type="number"
                name="price"
                id="price"
                step="0.01"
                min="1"
                className="form-control mb-3"
                placeholder="$ 0.00"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
            </div>

            <div className="col-12 col-md-6 text-start mb-4">
              <label htmlFor="size_id" className="form-label">
                Tamaño:
              </label>
              <select
                className="form-select mb-3"
                name="size_id"
                id="size_id"
                onChange={(e) => setSizeId(e.target.value)}
                value={sizeId}
              >
                <option value="0">Selecciona una opción</option>
                {sizes.map((size, key) => (
                  <option value={size.id} key={key}>
                    {size.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-6">
              <Link
                to={`/products/edit/${productId}`}
                className="btn btn-outline-secondary rounded-0"
              >
                Cancelar
              </Link>
            </div>
            <div className="col-6">
              <button
                className="btn btn-outline-success rounded-0"
                type="button"
                onClick={(e) => saveRecord(e)}
              >
                Guardar
              </button>
            </div>
          </form>
        ) : (
          <ContentDeniedMessage />
        )}
      </div>
    </div>
  );
}

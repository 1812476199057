import Axios from "axios";
import configUrl from "../lib/config.json";

//URL used to make request to the API
const httpUrl = configUrl.uri;

export const openSaleTicket = async (id) => {
  const info = await Axios.get(`${httpUrl}/pdf/get-sale-ticket/${id}`);
  console.log(info.data);
  window.open(info.data.ticketUrl, "_blank");
};

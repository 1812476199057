import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link, useParams } from "react-router-dom";
import CurrencyFormat from "../../components/CurrencyFormat";
import {
  LongDateTimeFormat,
  FormatDateTimeValue,
  EspDateToEngDate,
} from "../../components/FormatDate";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

function NewSale() {
  let { saleId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [succesMessage, setSuccessMessage] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [companySlug, setCompanySlug] = useState();

  const [products, setProducts] = useState([]);

  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [frequentCustomer, setFrequentCustomer] = useState(0);
  const [temporaryCustomer, setTemporaryCustomer] = useState("No especificado");
  const [moneyReceived, setMoneyReceived] = useState(0);
  const [change, setChange] = useState(0);
  const [status, setStatus] = useState(3);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [time, setTime] = useState("");
  const [user, setUser] = useState();
  const [userId, setUserId] = useState(0);

  const tableFieldStyles = {
    minWidth: "200px",
  };

  const fetchProducts = async (userId) => {
    const httpUrl = configUrl.uri;
    setIsLoading(true);
    const res = await Axios.get(
      httpUrl + `/products/fetch-pos-products/${userId}`
    );
    setProducts(await res.data);
    //console.log("Products", res);
    setIsLoading(false);
  };

  const fetchCustomers = async (userId) => {
    const httpUrl = configUrl.uri;
    const res = await Axios.get(httpUrl + `/customers/${userId}`);
    setCustomers(await res.data);
  };

  const getCompanyUser = async (userId) => {
    const httpUrl = configUrl.uri;
    const res = await Axios.get(
      httpUrl + `/users/get-company-user-data/${userId}`
    );
    setCompanyId(await res.data.company_id);
    setBranchId(await res.data.branch_id);
    setCompanySlug(await res.data.company.slug);
  };

  const addProductToCart = async (product) => {
    let maxQuantity = product.inventory[0].quantity;

    //console.log("Card Quantity:", maxQuantity);

    let productVariant;
    let currentSize;
    let sizeId;
    let sizeName;
    let sizePrice;

    if (product.variants.length > 0) {
      productVariant = document.querySelector(
        `.product-${product.id}-variants`
      );

      currentSize = productVariant.querySelector(".active-size");
      sizeId = parseInt(currentSize.getAttribute("sizeid"));
      sizeName = currentSize.getAttribute("sizename");
      sizePrice = currentSize.getAttribute("sizeprice");
    } else {
      sizeId = null;
    }

    //Check if The Added product exist.
    let findProductInCart = cart.find((i) => {
      return i.size_id
        ? i.product_id === product.id && i.size_id === sizeId
        : i.product_id === product.id;
    });

    if (findProductInCart) {
      let newCart = [];
      let newItem;

      cart.forEach((cartItem) => {
        if (cartItem.product_id === product.id && cartItem.size_id === sizeId) {
          let currentQuantity = cartItem.quantity + 1;
          let currentAddButton = document.querySelector(
            "#addProduct" + cartItem.product_id
          );
          if (currentQuantity >= maxQuantity) {
            currentAddButton.disabled = true;
          } else {
            currentAddButton.disabled = false;
          }

          newItem = {
            ...cartItem,
            product_id: cartItem.product_id,
            quantity: cartItem.quantity + 1,
            price_to_pay: cartItem.price * (cartItem.quantity + 1),
          };
          newCart.push(newItem);
          //console.log(newItem);
        } else {
          newCart.push(cartItem);
          //console.log(cartItem);
        }
      });
      setCart(newCart);
    } else {
      let addingProduct = {
        ...product,
        sale_id: "",
        product_id: product.id,
        quantity: 1,
        price_to_pay: product.price,
        size_id: sizeId !== "" || sizeId !== null ? sizeId : "",
        abbreviation: sizeName ? sizeName : "",
      };
      //console.log(addingProduct);
      setCart([...cart, addingProduct]);
    }
    console.log("Cart: ", cart);
    productVariant = null;
    currentSize = null;
    sizeId = null;
    sizeName = null;
    
  };

  const removeProduct = async (product) => {
    const httpUrl = configUrl.uri;
    //Creating new cart without that product
    const newCart = cart.filter(
      (cartItem) =>
        cartItem.size_id !== product.size_id || cartItem.id !== product.id
    );
    setCart(newCart);

    let newTotalAmount = 0;
    newCart.forEach((icart) => {
      newTotalAmount = newTotalAmount + parseFloat(icart.price_to_pay);
    });

    //Updating the sale with the new data.
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let data = new FormData();
    data.append("total_amount", newTotalAmount);

    Axios.post(
      httpUrl + `/sales/update-total-amount/${saleId}`,

      data,
      config
    );

    //Deleting the Sale item
    Axios.delete(httpUrl + `/sale-items/delete/${product.id}`).then((res) => {
      if (res["status"] === 200 || res["status"] === 201) {
        console.log(res);
      }
    });
  };

  const changeQuantity = async (product, newQuantity) => {
    const updatingCart = cart.map((cartItem) => {
      let currentAddButton = document.querySelector(
        "#addProduct" + cartItem.product_id
      );
      if (
        cartItem.product_id === product.product_id &&
        cartItem.size_id === product.size_id
      ) {
        if (newQuantity < product.inventory) {
          return {
            ...cartItem,
            quantity: parseFloat(newQuantity),
            price_to_pay: cartItem.price * parseFloat(newQuantity),
          };
        } else {
          currentAddButton.disabled = true;
          return {
            ...cartItem,
            quantity: parseFloat(product.inventory),
            price_to_pay: cartItem.price * parseFloat(product.inventory),
          };
        }
      }
      return cartItem;
    });
    setCart(updatingCart);
    //console.table(updatingCart);
  };

  const addTemporaryCustomer = async (e) => {
    setTemporaryCustomer(e.target.value);
    //console.log(e.target.value);
  };

  const addExistingCustomer = async (e) => {
    setFrequentCustomer(e.target.value);
  };

  const addPaymentMethod = async (e) => {
    setPaymentMethod(e.target.value);
  };

  const addStatus = async (e) => {
    setStatus(e.target.value);
  };

  const moneyOperation = async (newNoneyReceived) => {
    setMoneyReceived(newNoneyReceived);

    if (!newNoneyReceived <= 0) {
      const change = newNoneyReceived - totalAmount;
      setChange(change);
    }
  };

  const generateSale = async () => {
    const httpUrl = configUrl.uri;
    const apiUrl = httpUrl + `/sales/save`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    if (cart.length === 0) {
      alert("Porfavor agrega un producto para poder concretar la compra.");
    } else {
      let data = new FormData();

      const saleProducts = JSON.stringify(cart);

      let newDate = `${createdAt} ${time}`;
      console.log("New Date: ", newDate);

      data.append("temporary_customer", temporaryCustomer);
      data.append("customer_id", frequentCustomer);
      data.append("total_amount", totalAmount);
      data.append("money_received", moneyReceived);
      data.append("change", change);
      data.append("products", saleProducts);
      data.append("company_id", companyId);
      data.append("branch_id", branchId);
      data.append("status", status);
      data.append("payment_method", paymentMethod);
      data.append("user_id", userId);
      data.append("created_at", newDate);

      //console.log("Sale Products", saleProducts);

      // Sending data to the api
      Axios.post(apiUrl, data, config)
        .then(function (res) {
          if (res["status"] === 200) {
            reloadData();
            setSuccessMessage(200);
          } else {
            setSuccessMessage(500);
          }
          //console.log(res.data);
        })
        .catch(function (error) {
          //console.log(error);
          alert("Hubo un problema al intentar procesar la solicitud.");
        });
    }
  };

  const updateCart = async (cart) => {
    let newTotalAmount = 0;
    cart.forEach((icart) => {
      newTotalAmount = newTotalAmount + parseFloat(icart.price_to_pay);
    });
    setTotalAmount(newTotalAmount);
  };

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);
    getCompanyUser(await uId);
    fetchProducts(await uId);
    fetchCustomers(await uId);
  };

  const reloadData = () => {
    fetchProducts(userId);
    fetchCustomers(userId);
    setCreatedAt("");
    setTime("");
    setFrequentCustomer(0);
    setTemporaryCustomer("No Especificado");
    setCart([]);
    setTotalAmount(0);
    setMoneyReceived(0);
    setChange(0);
    setPaymentMethod(0);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const activateSize = (e, productId, newPrice) => {
    //Get current card element
    const currentCard = document.querySelector(
      `.product-${productId}-variants`
    );

    //Get sizes buttons that belongs to the current card
    const sizeButtons = currentCard.querySelectorAll(".size-btn");

    //Iterating buttons to remove active class
    sizeButtons.forEach((btn) => {
      btn.classList.remove("active-size");
    });

    //activating current button.
    e.target.classList.add("active-size");

    const updatedProducts = products.map((product) => {
      if (parseInt(product.id) === parseInt(productId)) {
        return { ...product, price: newPrice };
      }
      return product;
    });
    setProducts(updatedProducts);
    //console.log("Updated Products", updatedProducts);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    updateCart(cart);
  }, [cart]);

  return (
    <div className="my-3 px-lg-3">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/sales">Ventas</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Generar Nueva Venta
          </li>
        </ol>
      </nav>
      <h3 className="text-center text-md-start mb-4">Generar Nueva Venta</h3>
      {can("generate_sales") ? (
        <div>
          {/* Sale Cart */}
          <div className="card mb-4 shadow">
            <div className="card-body">
              <p className="text-dark my-4 text-center">
                Para generar un nuevo registro por favor modifica los siguientes
                campos y agrega productos a la orden.
              </p>
              <div className="row">
                <div className="col-12">
                  <div className="row justify-content-center justify-content-sm-end">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="createdAt" className="form-label">
                            <b>Fecha: </b>
                          </label>
                          <input
                            type="date"
                            name="createdAt"
                            id="createdAt"
                            className="form-control mb-3"
                            onChange={(e) => setCreatedAt(e.target.value)}
                            value={createdAt}
                          />
                        </div>
                        <div className="col-6">
                          <label htmlFor="time" className="form-label">
                            <b>Hora: </b>
                          </label>
                          <input
                            type="time"
                            name="time"
                            id="time"
                            className="form-control mb-3"
                            onChange={(e) => setTime(e.target.value)}
                            value={time}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 text-start">
                  <label htmlFor="name" className="form-label">
                    Cliente provisional:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Nombre del negocio o persona"
                    className="form-control mb-3"
                    onChange={(e) => addTemporaryCustomer(e)}
                    value={temporaryCustomer}
                  />
                </div>
                <div className="col-12 col-sm-6 text-start">
                  <label htmlFor="customer_id" className="form-label">
                    Cliente Frecuente:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="customer_id"
                    id="customer_id"
                    onChange={(e) => addExistingCustomer(e)}
                    value={frequentCustomer}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    <option value="0">No agregar</option>
                    {customers.map((customer, key) => (
                      <option value={customer.id} key={key}>
                        {customer.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="">
                        <tr>
                          <th scope="col">Opciones</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Tamaño</th>
                          <th scope="col">Precio</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col" className="text-end">
                            <p className="pe-2 mb-0">Precio a pagar</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.map((product, key) => (
                          <tr key={key}>
                            <td>
                              <button
                                className="btn btn-sm btn-outline-danger text-center"
                                title="Eliminar artículo"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target={`#confirmRecordDeletion-${key}`}
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                              <div
                                className="modal fade"
                                id={`confirmRecordDeletion-${key}`}
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex="-1"
                                aria-labelledby="confirmRecordDeletionLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="confirmRecordDeletionLabel"
                                      >
                                        Atención!
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        <div className="col-12">
                                          <p className="text-danger fw-bold text-center">
                                            ¿Esta seguro de eliminar este
                                            artículo de la venta?
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-12 text-center">
                                        <p className="mb-0">
                                          <b>Nombre:</b>
                                        </p>
                                        <p className="text-center">
                                          {product.name}
                                        </p>
                                      </div>
                                      {product.size_id ? (
                                        <div className="col-12 text-center">
                                          <p className="mb-0">
                                            <b>Tamaño:</b>
                                          </p>
                                          <p className="text-center">
                                            {product.abbreviation}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary rounded-0"
                                        data-bs-dismiss="modal"
                                      >
                                        Cancelar
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-outline-danger rounded-0"
                                        onClick={() => removeProduct(product)}
                                        data-bs-dismiss="modal"
                                      >
                                        Confirmar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{product.name}</td>
                            <td>
                              {product.abbreviation ? product.abbreviation : ""}
                            </td>
                            <td>
                              <CurrencyFormat price={product.price} />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control form-control-sm border-danger mx-auto"
                                style={{ maxWidth: "75px" }}
                                min="0.01"
                                step="0.01"
                                name="quantity"
                                value={product.quantity}
                                disabled={
                                  product.quantity >= product.inventory
                                    ? true
                                    : ""
                                }
                                onChange={(e) =>
                                  changeQuantity(product, e.target.value)
                                }
                              />
                            </td>
                            <td className="text-end">
                              <p className="pe-2 mb-0">
                                <CurrencyFormat price={product.price_to_pay} />
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Monto Total:</td>
                          <td className="text-end">
                            <p className="pe-2 mb-0 fw-bold text-success">
                              <CurrencyFormat price={totalAmount} />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Dinero recibido:</td>
                          <td>
                            <input
                              type="number"
                              name="moneyReceived"
                              id="moneyReceived"
                              min="0.01"
                              step="0.01"
                              placeholder=""
                              className="form-control text-end"
                              onChange={(e) => moneyOperation(e.target.value)}
                              value={moneyReceived}
                              style={tableFieldStyles}
                            />
                            {moneyReceived < totalAmount ? (
                              <div className="col-12">
                                <p className="text-danger mb-0">
                                  <small>
                                    El dinero recibido debe ser igual o mayor al
                                    monto total.
                                  </small>
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Cambio:</td>
                          <td className="text-end">
                            <p className="pe-2 mb-0 fw-bold text-primary">
                              <CurrencyFormat price={change} />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <label
                              htmlFor="paymentMethod"
                              className="form-label text-start"
                            >
                              Método de Pago:
                            </label>
                          </td>
                          <td className="text-end">
                            <select
                              className="form-select"
                              name="paymentMethod"
                              id="paymentMethod"
                              onChange={(e) => addPaymentMethod(e)}
                              value={paymentMethod}
                              style={tableFieldStyles}
                            >
                              <option value="0">No especificado</option>
                              <option value="1">Efectivo</option>
                              <option value="2">
                                Transferencia electrónica
                              </option>
                              <option value="3">Tarjeta de Débito</option>
                              <option value="4">Tarjeta de Crédito</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <label
                              htmlFor="status"
                              className="form-label text-start"
                            >
                              Estado de Orden:
                            </label>
                          </td>
                          <td className="text-end">
                            <select
                              className="form-select"
                              name="status"
                              id="status"
                              onChange={(e) => addStatus(e)}
                              value={status}
                            >
                              <option value="0" disabled>
                                Selecciona una opcion
                              </option>
                              <option value="0">No especificado</option>
                              <option value="1">Pendiente</option>
                              <option value="2">En proceso</option>
                              <option value="3">Completado</option>
                            </select>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="col-12">
                    {succesMessage === 200 ? (
                      <div
                        className="alert alert-success d-flex align-items-center alert-dismissible fade show"
                        role="alert"
                      >
                        <div>Registro generado exitosamente.</div>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-6 text-center">
                  <Link
                    to="/sales"
                    className="btn btn-outline-secondary rounded-0"
                  >
                    Cancelar
                  </Link>
                </div>
                <div className="col-6 text-center">
                  <button
                    className="btn btn-outline-success rounded-0"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#confirmSale"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Product List */}
          <div className="row">
            <div className="col-12">
              <h3 className="text-start mt-2 mb-4">Lista de Productos</h3>
            </div>

            <div className="col-12">
              <div className="row">
                {isLoading
                  ? "Cargando"
                  : products.map((product, key) =>
                      product.inventory.length > 0 ? (
                        <div className="col-12 col-sm-6 col-xl-4" key={key}>
                          <div
                            className={`card mb-3 shadow border-0 product-card-${product.id}`}
                          >
                            <div className="row g-0">
                              <div className="col-5">
                                <img
                                  src={`${configUrl.baseUrl}/storage/companies/${companySlug}/products/images/${product.image}`}
                                  className="w-100 h-100 rounded-start"
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                  alt={`${product.name}`}
                                />
                              </div>
                              <div className="col-7">
                                <div className="card-body px-2 px-xxl-3 h-100 d-flex justify-content-center align-items-center">
                                  <div className="w-100">
                                    <div className="row">
                                      <div className="col-12 col-xl-7">
                                        <h6 className="h6 card-title text-start mb-2 fw-bolder">
                                          {product.name}
                                        </h6>
                                      </div>
                                      <div className="col-12 col-xl-5">
                                        <p className="card-text text-start text-muted mb-2">
                                          <CurrencyFormat
                                            price={
                                              product.price === 0 ||
                                              (product.price === null &&
                                                product.variants.length > 0)
                                                ? (product.price =
                                                    product.variants[0].price)
                                                : product.price
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>

                                    {product.variants.length > 0 ? (
                                      <div
                                        className={`d-flex flex-wrap mb-2 product-${product.id}-variants`}
                                      >
                                        <small className="my-auto me-2 fw-bold">
                                          Size
                                        </small>
                                        {product.variants.map(
                                          (variant, key) => (
                                            <button
                                              key={key}
                                              dataproductid={variant.product_id}
                                              sizeid={variant.size_id}
                                              sizename={
                                                variant.size.abbreviation
                                              }
                                              sizeprice={variant.price}
                                              onClick={(e) =>
                                                activateSize(
                                                  e,
                                                  variant.product_id,
                                                  variant.price
                                                )
                                              }
                                              className={`btn btn-sm btn-outline-dark rounded-pill mb-1 me-1 size-btn ${
                                                key === 0 ? "active-size" : ""
                                              }`}
                                            >
                                              {variant.size.abbreviation}
                                            </button>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <button
                                      id={"addProduct" + product.id}
                                      className="btn btn-sm btn-outline-danger rounded-0 w-100 rounded-pill"
                                      onClick={() => addProductToCart(product)}
                                      disabled={
                                        product.inventory[0].quantity === 0
                                          ? true
                                          : false
                                      }
                                    >
                                      Agregar <i className="fas fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
              </div>
            </div>
          </div>

          {/* Confirm Sale Modal */}
          <div
            className="modal fade"
            id="confirmSale"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="confirmSaleLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="confirmSaleLabel">
                    Atención!
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <p className="text-center">
                        ¿Desea generar este registro de venta?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="row w-100">
                    <div className="col-6 text-start">
                      <button
                        type="button"
                        className="btn btn-outline-secondary rounded-0"
                        data-bs-dismiss="modal"
                      >
                        No, Cancelar
                      </button>
                    </div>
                    <div className="col-6 text-end">
                      <button
                        type="button"
                        className="btn btn-outline-success rounded-0"
                        onClick={() => generateSale()}
                        data-bs-dismiss="modal"
                      >
                        Si, Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="card my-3 shadow">
            <div className="card-header">
              <h3 className="text-start">Editar Venta</h3>
            </div>
            <div className="card-body">
              <ContentDeniedMessage />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewSale;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import withRouter from "../../HOC/withRouter";
import Axios from "axios";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

class EditCategory extends Component {
  state = {
    name: "",
    description: "",
    parent_id: "",
    status: "",
    categories: [],
    user: [],
    userId: 0,
    permissions: [],
  };

  componentDidMount() {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const currentPermissions = localStorage.getItem("permissions");

    this.setState({ companyId: companyId });
    this.setState({ userId: userId });
    this.setState({ permissions: currentPermissions });

    const httpUrl = configUrl.uri;
    const id = this.props.params.id;
    //console.log(id);
    //return <h3>Hello, {props.name}</h3>;
    Axios.get(`${httpUrl}/categories/edit/${id}`).then((res) => {
      this.setState({ name: res.data.category.name });
      this.setState({ description: res.data.category.description });
      this.setState({ parent_id: res.data.category.parent_id });
      this.setState({ status: res.data.category.status });
      this.setState({ categories: res.data.categories });

      //console.log(res.data);
    });
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    const httpUrl = configUrl.uri;
    const id = this.props.params.id;
    const apiUrl = httpUrl + `/categories/update/${id}`;
    console.log(apiUrl, "apiurl");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let category = new FormData();

    category.append("name", this.state.name);
    category.append("description", this.state.description);
    category.append("parent_id", this.state.parent_id);
    category.append("status", this.state.status);

    Axios.post(apiUrl, category, config)
      .then(function (response) {
        if (response["status"] === 200) {
          alert("Registro actualizado exitosamente.");
          window.location.href = "/categories";
        }
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });

    //console.log(this.state);
    e.preventDefault();
  };

  render() {
    return (
      <div className="my-3">
        <div className="row mb-3">
          <div className="col-12 my-auto">
            <h3 className="text-center text-md-start mb-3 mb-md-0">
              Editar Categoría
            </h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {this.can("edit_categories") ? (
              <form
                onSubmit={this.onSubmit}
                className="row justify-content-center justify-content-md-start rounded py-5 px-3"
              >
                <p className="text-dark mb-3 text-center">
                  Para editar registro por favor rellena los siguientes campos.
                </p>
                <div className="col-12 col-md-6 text-start">
                  <label htmlFor="name" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Nombre"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.name}
                  />
                </div>
                <div className="col-12 col-md-6 text-start">
                  <label htmlFor="parent_id" className="form-label">
                    Categoría Padre:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="parent_id"
                    id="parent_id"
                    onChange={this.onChange}
                    value={this.state.parent_id}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    <option value="0">Ninguno</option>
                    {this.state.categories.map((cat, key) => (
                      <option value={cat.id} key={key}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6 text-start">
                  <label htmlFor="status" className="form-label">
                    Estado:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="status"
                    id="status"
                    onChange={this.onChange}
                    value={this.state.status}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="1">Activado</option>
                    <option value="0">Desactivado</option>
                  </select>
                </div>
                <div className="col-12 text-start">
                  <label htmlFor="description" className="form-label">
                    Descripción:
                  </label>
                  <textarea
                    className="form-control mb-3"
                    name="description"
                    id="description"
                    placeholder="Descripción"
                    onChange={this.onChange}
                    value={this.state.description}
                  ></textarea>
                </div>
                <div className="row justify-content-center">
                  <div className="col-6">
                    <Link
                      to="/categories"
                      className="btn btn-outline-secondary rounded-0"
                    >
                      Cancelar
                    </Link>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-outline-success rounded-0"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <ContentDeniedMessage />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const hocEditCategory = withRouter(EditCategory);

export default hocEditCategory;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import FormatDate from "../../../components/FormatDate";
import Pagination from "../../../components/Pagination";
import CurrencyFormat from "../../../components/CurrencyFormat";
import config from "../../../lib/config.json";
import ContentDeniedMessage from "../../../components/ContentDeniedMessage";

function ExpenseIndex() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [companyId, setCompanyId] = useState(0);
  const [userId, setUserId] = useState();
  const [permissions, setPermissions] = useState([]);
  const httpUrl = config.uri;
  const imageStyles = {
    maxWidth: "175px",
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = records.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const getUser = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const companyId = parseInt(localStorage.getItem("companyId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setCompanyId(await companyId);
    setPermissions(await currentPermissions);
    fetchRecords(await uId);
  };

  useEffect(() => {
    getUser();
  }, []);

  const deleteRecord = (e, id = null) => {
    if (id) {
      //console.log(id);
      Axios.delete(httpUrl + `/expenses/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            fetchRecords(userId);
          }
        })
        .catch((error) => {
          //console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  const fetchRecords = async (companyId) => {
    setIsLoading(true);
    const res = await Axios.get(httpUrl + `/expenses/${companyId}`);
    setRecords(await res.data.expenses);
    //console.log(res);
    setIsLoading(false);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="my-3 px-lg-3">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/finance">Finanzas</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Gastos
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col-12 col-md-6 my-auto text-md-start">
          <h2>Gastos</h2>
        </div>
        <div className="col-12 col-md-6 my-auto text-center text-lg-end">
          {can("expense_cat_index") ? (
            <Link
              to="/finance/expenses/categories"
              className="btn btn-outline-warning rounded-0 ms-3 ms-sm-0 me-3 mb-3 mb-sm-0"
            >
              Ver Categorías <i className="fas fa-stream"></i>
            </Link>
          ) : (
            ""
          )}
          {can("add_expenses") ? (
            <Link
              to="/finance/expenses/add"
              className="btn btn-outline-primary rounded-0"
            >
              Agregar Nuevo Gasto <i className="fas fa-plus"></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="card my-3 shadow">
        {can("expenses_index") ? (
          <div className="card-body">
            {isLoading ? (
              <div className="alert alert-primary" role="alert">
                Cargando...
              </div>
            ) : (
              <div className="table-responsive" style={{ minHeight: "400px" }}>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-center">Nombre</th>
                      <th className="text-center">Monto</th>
                      <th className="text-center">Categoría</th>
                      <th className="text-center">Sucursal</th>
                      <th className="text-center">Fecha</th>
                      <th className="text-center">Opciones</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {currentItems.map((expense, key) => (
                      <tr key={key}>
                        <td>{expense.id}</td>
                        <td>{expense.name}</td>
                        <td>
                          <CurrencyFormat price={expense.amount} />
                        </td>
                        <td>{expense.expense_category.name}</td>
                        <td>{expense.branch.name}</td>
                        <td>{expense.date}</td>
                        <td style={{ minWidth: "100px" }}>
                          {can("edit_expenses") ? (
                            <Link
                              to={`/finance/expenses/edit/${expense.id}`}
                              className="btn btn-primary btn-sm rounded-0 mx-1"
                              title={`Editar ${expense.name}`}
                            >
                              <i className="fas fa-pen"></i>
                            </Link>
                          ) : (
                            ""
                          )}
                          {can("delete_expenses") ? (
                            <button
                              type="button"
                              className="btn btn-danger btn-sm rounded-0 mx-1"
                              data-bs-toggle="modal"
                              data-bs-target={`#confirmRecordDeletion-${expense.id}`}
                              title={`Eliminar ${expense.name}`}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          <div
                            className="modal fade"
                            id={`confirmRecordDeletion-${expense.id}`}
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="confirmRecordDeletionLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="confirmRecordDeletionLabel"
                                  >
                                    Atención!
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-12">
                                      <p className="text-danger fw-bold">
                                        ¿Esta seguro de eliminar este registro?
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <p className="mb-0">
                                      <b>Nombre:</b>
                                    </p>
                                    <p>{expense.name}</p>
                                  </div>
                                  <div className="col-12">
                                    <p className="mb-0">
                                      <b>Monto:</b>
                                    </p>
                                    <p>
                                      <CurrencyFormat price={expense.amount} />
                                    </p>
                                  </div>
                                  <div className="col-12">
                                    <p className="mb-0">
                                      <b>Fecha:</b>
                                    </p>
                                    <p>{expense.date}</p>
                                  </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary rounded-0"
                                    data-bs-dismiss="modal"
                                  >
                                    Cancelar
                                  </button>
                                  {can("delete_expenses") ? (
                                    <form
                                      onSubmit={(e) =>
                                        deleteRecord(e, expense.id)
                                      }
                                    >
                                      <input
                                        type="hidden"
                                        name="id"
                                        value={expense.id}
                                      />
                                      <button
                                        type="submit"
                                        className="btn btn-outline-danger rounded-0"
                                        data-bs-dismiss="modal"
                                      >
                                        Confirmar
                                      </button>
                                    </form>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={records.length}
              paginate={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </div>
        ) : (
          <div className="card-body">
            <ContentDeniedMessage />
          </div>
        )}
      </div>
    </div>
  );
}
export default ExpenseIndex;

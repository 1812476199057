class User {
  constructor() {
    this.init();
    this.init2();
    this.initCompanyData();
  }

  init() {
    this.id = localStorage.getItem("userId");
    this.name = localStorage.getItem("userName");
    this.email = localStorage.getItem("userEmail");
    this.token = localStorage.getItem("userToken");
    this.loggedIn = localStorage.getItem("userLoggedIn");
  }

  init2() {
    this.permissions = localStorage.getItem("permissions");
  }

  initCompanyData() {
    this.name = localStorage.getItem("companyId");
    this.name = localStorage.getItem("branchId");
    this.name = localStorage.getItem("companyName");
  }

  /**
   *
   * @param data object
   * @param data.name string
   * @param data.email string
   * @param callback function
   */
  authenticated(data) {
    localStorage.setItem("userId", data.user.id);
    localStorage.setItem("userName", data.user.name);
    localStorage.setItem("userEmail", data.user.email);
    localStorage.setItem("userToken", data.token);
    localStorage.setItem("userLoggedIn", true);

    this.init();
  }

  setPermissions(permissions) {
    localStorage.setItem("permissions", Array(permissions));
  }

  setCompanyData(data) {
    localStorage.setItem("companyId", data.company.id);
    localStorage.setItem("branchId", data.branch.id);
    localStorage.setItem("companyName", data.company.name);
  }

  logout() {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("permissions");
    localStorage.removeItem("companyId");
    localStorage.removeItem("branchId");
    localStorage.removeItem("companyName");
  }

  /**
   *
   * @return {boolean}
   */
  isLoggedIn() {
    return Boolean(this.loggedIn) === true;
  }
}

export default new User();

import React from "react";
import { Link, NavLink } from "react-router-dom";
import User from "../modules/Auth/User";
import config from "../lib/config.json";

export default function Navbar(props) {
  const can = (permission) => {
    if (props.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const signOut = () => {
    User.logout();
    window.location = "/login";
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white mb-0 border-bottom main-nav">
      <div className="container-fluid">
        <Link
          to="/"
          className="navbar-brand fw-bolder text-uppercase ms-md-3 text-danger"
        >
          {localStorage.getItem("companyName")}
        </Link>
        <div className="d-flex">
          <Link
            className="text-secondary mx-2 my-auto d-lg-none"
            aria-current="page"
            to="#"
          >
            <i className="fas fa-bell"></i>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarMenu"
            aria-controls="navbarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse justify-content-end mt-2"
          id="navbarMenu"
        >
          <ul className="navbar-nav">
            {can("dashboard") ? (
              <li className="nav-item d-lg-none">
                <NavLink className="nav-link text-center" to="/">
                  Dashboard <i className="fas fa-tachometer-alt"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("point_of_sale") ? (
              <li className="nav-item d-lg-none">
                <NavLink className="nav-link text-center" to="/point-of-sale">
                  Punto de Venta <i className="fas fa-cash-register"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("sales_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink className="nav-link text-center" to="/sales">
                  Ventas <i className="fas fa-coins"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("categories_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink to="/categories" className="nav-link text-center">
                  Categorias <i className="fas fa-stream"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("products_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink to="/products" className="nav-link text-center">
                  Productos <i className="fas fa-boxes"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("customers_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink to="/customers" className="nav-link text-center">
                  Clientes <i className="fas fa-users"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("branches_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink to="/branches" className="nav-link text-center">
                  Sucursales <i className="fas fa-project-diagram"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("inventories_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink to="/inventory" className="nav-link text-center">
                  Inventario <i className="fas fa-warehouse"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("finance_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink to="/finance" className="nav-link text-center">
                  Finanzas <i className="fas fa-hand-holding-usd"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {can("users_index") ? (
              <li className="nav-item d-lg-none">
                <NavLink to="/users" className="nav-link text-center">
                  Usuarios <i className="fas fa-users"></i>
                </NavLink>
              </li>
            ) : (
              ""
            )}

            <li className="nav-item d-lg-none">
              <div className="dropdown text-center">
                <button
                  className="btn btn-white dropdown-toggle text-secondary"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="fas fa-user-circle me-2"></span>
                  {localStorage.getItem("userName")}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link className="dropdown-item" to="#">
                      Mi Perfil
                    </Link>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={signOut}>
                      Cerrar sesión
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="btn-group dropstart d-none d-lg-block">
          <button
            type="button"
            className="btn btn-white dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {localStorage.getItem("userName")}
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link className="dropdown-item" to="#">
                Mi perfil
              </Link>
            </li>
            <li>
              <button className="dropdown-item" onClick={signOut}>
                Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

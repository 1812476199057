import React, { useEffect, useState } from "react";
import Axios from "axios";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { LongDateTimeFormat } from "../../components/FormatDate";
import Pagination from "../../components/Pagination";
import configUrl from "../../lib/config.json";

function RolesIndex() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const httpUrl = configUrl.uri;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = records.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const fetchRecords = async () => {
    setIsLoading(true);
    const res = await Axios.get(httpUrl + `/roles`);
    setRecords(await res.data.roles);
    console.log(res.data.roles);
    setIsLoading(false);
  };

  const deleteRecord = (e, id = null) => {
    if (id) {
      console.log(id);
      Axios.delete(httpUrl + `/roles/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            alert("Registro eliminado correctamente");
            fetchRecords();
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  const http = Axios.create({
    baseURL: configUrl.baseUrl,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });

  const httpUser = Axios.create({
    baseURL: configUrl.baseUrl,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    },
    withCredentials: true,
  });

  const getUser = async () => {
    const user = await httpUser.get("/api/user");
    const permissions = await http.get(
      `/api/users/permissions/${user.data.id}`
    );

    setUserId(user.data.id);
    setPermissions(await permissions.data);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    fetchRecords();
  }, []);

  return (
    <div className="View">
      <Navbar permissions={permissions} />
      <div className="container-fluid content">
        <div className="row">
          <div className="col-12 col-lg-3 col-xl-2 px-0 d-none d-lg-block">
            <Sidebar userId={userId} permissions={permissions}></Sidebar>
          </div>
          <div className="col-12 col-lg-9 col-xl-10 bg-light">
            <div className="card my-3">
              <div className="card-header">
                <div className="row">
                  <div className="col-12 col-md-6 my-auto text-md-start">
                    <h2>Roles</h2>
                  </div>
                  <div className="col-12 col-md-6 my-auto text-md-end">
                    {can("create_roles") ? (
                      <Link
                        to="/roles/new"
                        className="btn btn-outline-primary rounded-0"
                      >
                        Crear Rol <i className="fas fa-plus"></i>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                {records && records.length > 0 ? (
                  [
                    isLoading ? (
                      "Cargando"
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover align-middle">
                          <thead>
                            <tr>
                              <th className="text-center">ID</th>
                              <th className="text-center">Nombre</th>
                              <th
                                className="text-center"
                                style={{ minWidth: "100px" }}
                              >
                                Opciones
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td style={{ minWidth: "100px" }}>
                                  {can("edit_roles") ? (
                                    <Link
                                      to={`/roles/edit/${item.id}`}
                                      className="btn btn-primary btn-sm rounded-0 mx-1"
                                      title={`Editar ${item.name}`}
                                    >
                                      <i className="fas fa-pen"></i>
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  {can("delete_roles") ? (
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm rounded-0 mx-1"
                                      data-bs-toggle="modal"
                                      data-bs-target={`#confirmRecordDeletion-${item.id}`}
                                      title={`Eliminar ${item.name}`}
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className="modal fade"
                                    id={`confirmRecordDeletion-${item.id}`}
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex="-1"
                                    aria-labelledby="confirmRecordDeletionLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog modal-dialog-centered">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="confirmRecordDeletionLabel"
                                          >
                                            Atención!
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                        <div className="modal-body">
                                          <div className="row">
                                            <div className="col-12">
                                              <p className="text-danger fw-bold">
                                                ¿Esta seguro de eliminar este
                                                registro?
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            <p className="mb-0">
                                              <b>Nombre:</b>
                                            </p>
                                            <p>{item.name}</p>
                                          </div>
                                          <div className="col-12">
                                            <p className="mb-0">
                                              <b>Creado:</b>
                                            </p>
                                            <p>
                                              <LongDateTimeFormat
                                                date={item.created_at}
                                              />
                                            </p>
                                          </div>
                                        </div>
                                        <div className="modal-footer justify-content-between">
                                          <button
                                            type="button"
                                            className="btn btn-outline-secondary rounded-0"
                                            data-bs-dismiss="modal"
                                          >
                                            Cancelar
                                          </button>
                                          {can("delete_roles") ? (
                                            <form
                                              onSubmit={(e) =>
                                                deleteRecord(e, item.id)
                                              }
                                            >
                                              <input
                                                type="hidden"
                                                name="id"
                                                value={item.id}
                                              />
                                              <button
                                                type="submit"
                                                className="btn btn-outline-danger rounded-0"
                                                data-bs-dismiss="modal"
                                              >
                                                Confirmar
                                              </button>
                                            </form>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ),
                  ]
                ) : (
                  <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>
                      Necesitas agregar un nuevo registro para ver el listado en
                      esta sección!
                    </strong>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                )}

                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={records.length}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesIndex;

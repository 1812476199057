import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import config from "../../lib/config.json";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import CurrencyFormat from "../../components/CurrencyFormat";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  //const [products, setProducts] = useState([]);
  const [daysOfTheWeek, setDaysOfTheWeek] = useState([]);
  const [productLength, setProductLength] = useState(0);
  const [salesData, setSalesData] = useState([]);
  //Data of the day
  const [productsSoldOnTheDay, setProductsSoldOnTheDay] = useState([]);
  const [totalSaleOfTheDay, setTotalSaleOfTheDay] = useState(0);
  const [productNamesOnTheDay, setProductNamesOnTheDay] = useState([]);
  const [productQuantityOnTheDay, setProductQuantityOnTheDay] = useState([]);
  const [moneyEarnedByProductOnTheDay, setMoneyEarnedByProductOnTheDay] =
    useState([]);
  //Data of the week
  const [dailySalesIncome, setDailySalesIncome] = useState([]);
  const [productNamesOnTheWeek, setProductNamesOnTheWeek] = useState([]);
  const [productQuantityOnTheWeek, setProductQuantityOnTheWeek] = useState([]);
  const [moneyEarnedByProductOnTheWeek, setMoneyEarnedByProductOnTheWeek] =
    useState([]);
  //const [dataOfProductSoldOnTheweek, setDataOfProductSoldOnTheweek] = useState([]);
  const [productsSoldOnTheweek, setProductsSoldOnTheWeek] = useState([]);

  const [userId, setUserId] = useState(0);
  const [companyUser, setCompanyUser] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const cardStyles = {
    height: "11rem",
  };

  const http = Axios.create({
    baseURL: config.baseUrl,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });

  const getProducts = async (userId) => {
    const res = await http.get(`/api/products/${userId}`);
    //setProducts(products.data);
    setProductLength(res.data.products.length);
    setCompanyUser(res.data.companyUser.company);
    //console.log("products data: ", res);
  };

  const getSaleSummary = async (userId) => {
    const res = await http.get(`/api/sales/get-sale-summary/${userId}`);
    setSalesData(res.data.sales);
    //Sale information of the current day
    setTotalSaleOfTheDay(res.data.moneyEarnedToday);
    //Complete Data of the Week
    setProductsSoldOnTheDay(res.data.productsSoldOnTheDay);
    //Data Of the Today
    const todayProductLabels = res.data.productsSoldOnTheDay.map(
      (item) => item.product.name
    );
    const todayProductQuantitySold = res.data.productsSoldOnTheDay.map(
      (item) => item.quantitySold
    );
    const todayProductMoneyEarned = res.data.productsSoldOnTheDay.map(
      (item) => item.moneyEarned
    );
    setProductNamesOnTheDay(todayProductLabels);
    setProductQuantityOnTheDay(todayProductQuantitySold);
    setMoneyEarnedByProductOnTheDay(todayProductMoneyEarned);

    //Complete Data of the Week
    setProductsSoldOnTheWeek(res.data.productsSoldOnTheWeek);
    //Sale information of the current week
    setDaysOfTheWeek(res.data.daysOfTheWeek);
    setDailySalesIncome(res.data.dailySalesIncome);
    //Filtered Data Of the Week
    const weekProductLabels = res.data.productsSoldOnTheWeek.map(
      (item) => item.product.name
    );
    const weekProductQuantitySold = res.data.productsSoldOnTheWeek.map(
      (item) => item.quantitySold
    );
    const weekProductMoneySold = res.data.productsSoldOnTheWeek.map(
      (item) => item.moneyEarned
    );
    setProductNamesOnTheWeek(weekProductLabels);
    setProductQuantityOnTheWeek(weekProductQuantitySold);
    setMoneyEarnedByProductOnTheWeek(weekProductMoneySold);
    console.log("Sales Data", res);
  };

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);
    getProducts(await uId);
    getSaleSummary(await uId);

    //console.log("Permissions", currentPermissions);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-3">
      <h3 className="text-center text-md-start">Dashboard</h3>
      <p className="fs-3 text-center">
        <span className="text-capitalize fw-bold">
          {localStorage.getItem("userName")}
        </span>
        , bienvenido al Sistema de información de{" "}
        <span className="text-capitalize fw-bold">{companyUser.name}</span>
      </p>
      {can("dashboard") ? (
        <div>
          <p className="fs-5 text-center">
            Este es un resumen de los datos que recopilamos esta semana.
          </p>
          <div className="row justify-content-center justify-content-md-start mb-4">
            <div className="col-12 col-sm-6 col-md-4 col-xl-3">
              <div className="card shadow mb-3" style={cardStyles}>
                <div className="card-body text-center  d-grid align-content-center">
                      <h6 className="">Número de Productos de la empresa</h6>
                      <p className="fs-2 text-primary mb-0">{productLength}</p>
                      <Link
                        to="/products"
                        className="btn btn-link text-decoration-none"
                      >
                        Ir a modulo
                      </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-3">
              <div className="card shadow mb-3" style={cardStyles}>
                <div className="card-body text-center d-grid align-content-center">
                  <h6 className="">Ingreso total de ventas del día</h6>
                  <p className="fs-2 text-success mb-0">
                    <CurrencyFormat price={totalSaleOfTheDay} />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center justify-content-md-start mb-5">
            <div className="col-12">
              <h6 className="mb-3">Ingreso semanal de ventas por producto</h6>
            </div>
            {productsSoldOnTheweek.map((item) => (
              <div
                className="col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2"
                key={item.id}
              >
                <div className="card shadow mb-3" style={cardStyles}>
                  <div className="card-body text-center d-grid align-content-center">
                    <p className="text-success mb-3">
                      <b>{item.product.name} </b>
                    </p>
                    <p className="text-success">
                      <CurrencyFormat price={item.moneyEarned} />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div
              className="col-12 col-sm-6 col-md-8 col-xl-7 col-xxl-6
              "
            >
              {/* Sales Of the Day */}
              <div className="card mb-4 shadow">
                <div className="card-body">
                  <h5 className="card-title">Ventas del día</h5>
                  {moneyEarnedByProductOnTheDay.length > 0 ? (
                    <Bar
                      data={{
                        labels: productNamesOnTheDay,
                        datasets: [
                          {
                            label: "Ganancías",
                            data: moneyEarnedByProductOnTheDay,
                            backgroundColor: "rgba(16, 131, 74, 0.5)",
                            borderColor: "rgba(16, 131, 74, 1)",
                          },
                        ],
                      }}
                      options={{ maintainAspectRatio: true }}
                    />
                  ) : (
                    <p className="alert alert-primary text-muted">
                      Aun no se ha realizado alguna venta.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-12 col-sm-6 col-md-4 col-xl-5 col-xxl-6
              "
            >
              {/* Products Sold on the day */}
              <div className="card mb-4 shadow">
                <div className="card-body">
                  <h5 className="card-title">
                    Cantidad de productos vendidos del día
                  </h5>
                  {productQuantityOnTheDay.length > 0 ? (
                    <Pie
                      data={{
                        labels: productNamesOnTheDay,
                        datasets: [
                          {
                            label: "Cantidad",
                            data: productQuantityOnTheDay,
                            backgroundColor: [
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(54, 162, 235, 0.2)",
                              "rgba(255, 206, 86, 0.2)",
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(153, 102, 255, 0.2)",
                              "rgba(255, 159, 64, 0.2)",
                            ],
                            borderColor: [
                              "rgba(255, 99, 132, 1)",
                              "rgba(54, 162, 235, 1)",
                              "rgba(255, 206, 86, 1)",
                              "rgba(75, 192, 192, 1)",
                              "rgba(153, 102, 255, 1)",
                              "rgba(255, 159, 64, 1)",
                            ],
                          },
                        ],
                      }}
                      options={{ maintainAspectRatio: true }}
                    />
                  ) : (
                    <p className="alert alert-primary text-muted">
                      Aun no se ha realizado alguna venta.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-12 col-sm-6 col-md-8 col-xl-7 col-xxl-6
              "
            >
              {/* Sales Of the Week */}
              <div className="card mb-4 shadow">
                <div className="card-body">
                  <h5 className="card-title">Ventas de la semana actual</h5>
                  {dailySalesIncome.length > 0 ? (
                    <Bar
                      data={{
                        labels: daysOfTheWeek,
                        datasets: [
                          {
                            label: "Ganancías",
                            data: dailySalesIncome,
                            backgroundColor: "rgba(16, 131, 74, 0.5)",
                            borderColor: "rgba(16, 131, 74, 1)",
                          },
                        ],
                      }}
                      options={{ maintainAspectRatio: true }}
                    />
                  ) : (
                    <p className="alert alert-primary text-muted">
                      Aun no se han registrado ventas esta semana.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-12 col-sm-6 col-md-4 col-xl-5 col-xxl-6
              "
            >
              {/* Products Sold on the week */}
              <div className="card mb-4 shadow">
                <div className="card-body">
                  <h5 className="card-title">
                    Cantidad de productos vendidos esta semana
                  </h5>
                  {productQuantityOnTheWeek.length > 0 ? (
                    <Pie
                      data={{
                        labels: productNamesOnTheWeek,
                        datasets: [
                          {
                            label: "Cantidad",
                            data: productQuantityOnTheWeek,
                            backgroundColor: [
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(54, 162, 235, 0.2)",
                              "rgba(255, 206, 86, 0.2)",
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(153, 102, 255, 0.2)",
                              "rgba(255, 159, 64, 0.2)",
                            ],
                            borderColor: [
                              "rgba(255, 99, 132, 1)",
                              "rgba(54, 162, 235, 1)",
                              "rgba(255, 206, 86, 1)",
                              "rgba(75, 192, 192, 1)",
                              "rgba(153, 102, 255, 1)",
                              "rgba(255, 159, 64, 1)",
                            ],
                          },
                        ],
                      }}
                      options={{ maintainAspectRatio: true }}
                    />
                  ) : (
                    <p className="alert alert-primary text-muted">
                      Aun no se han registrado ventas esta semana.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

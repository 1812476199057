import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { LongDateTimeFormat } from "../../components/FormatDate";
import Pagination from "../../components/Pagination";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

function BranchesIndex() {
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const httpUrl = configUrl.uri;
  const [user, setUser] = useState();
  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = branches.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setIsLoading(true);

    setUserId(await uId);
    setPermissions(await currentPermissions);
    const res = await Axios.get(httpUrl + `/branches/${uId}`);
    setBranches(await res.data);
    //console.log(res.data);

    setIsLoading(false);
  };

  const deleteRecord = (e, id = null) => {
    if (id) {
      //console.log(id);
      Axios.delete(httpUrl + `/branches/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            alert("Registro eliminado correctamente");
            getData();
          }
        })
        .catch((error) => {
          //console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card my-3">
      <div className="card-header">
        <div className="row">
          <div className="col-12 col-md-6 my-auto text-md-start">
            <h2>Sucursales</h2>
          </div>
          <div className="col-12 col-md-6 my-auto text-md-end">
            {can("add_branches") ? (
              <Link
                to="/branches/new"
                className="btn btn-outline-primary rounded-0"
              >
                Nueva Sucursal <i className="fas fa-plus"></i>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {can("branches_index") ? (
        <div className="card-body">
          {branches && branches.length > 0 ? (
            [
              isLoading ? (
                "Cargando"
              ) : (
                <div className="table-responsive">
                  <table className="table table-bordered table-hover align-middle">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Nombre</th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          Opciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((branch, index) => (
                        <tr key={index}>
                          <td>{branch.id}</td>
                          <td>{branch.name}</td>
                          <td style={{ minWidth: "100px" }}>
                            {can("edit_branches") ? (
                              <Link
                                to={`/branches/edit/${branch.id}`}
                                className="btn btn-primary btn-sm rounded-0 mx-1"
                                title={`Editar ${branch.name}`}
                              >
                                <i className="fas fa-pen"></i>
                              </Link>
                            ) : (
                              ""
                            )}
                            {can("delete_branches") ? (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm rounded-0 mx-1"
                                data-bs-toggle="modal"
                                data-bs-target={`#confirmRecordDeletion-${branch.id}`}
                                title={`Eliminar ${branch.name}`}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            <div
                              className="modal fade"
                              id={`confirmRecordDeletion-${branch.id}`}
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabIndex="-1"
                              aria-labelledby="confirmRecordDeletionLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="confirmRecordDeletionLabel"
                                    >
                                      Atención!
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-12">
                                        <p className="text-center text-danger fw-bold">
                                          ¿Esta seguro de eliminar este
                                          registro?
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <p className="text-center mb-0">
                                        <b>Nombre:</b>
                                      </p>
                                      <p className="text-center">
                                        {branch.name}
                                      </p>
                                    </div>
                                    <div className="col-12">
                                      <p className="text-center mb-0">
                                        <b>Creado:</b>
                                      </p>
                                      <p className="text-center">
                                        <LongDateTimeFormat date={branch.created_at} />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="modal-footer justify-content-between">
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary rounded-0"
                                      data-bs-dismiss="modal"
                                    >
                                      Cancelar
                                    </button>
                                    {can("delete_branches") ? (
                                      <form
                                        onSubmit={(e) =>
                                          deleteRecord(e, branch.id)
                                        }
                                      >
                                        <input
                                          type="hidden"
                                          name="id"
                                          value={branch.id}
                                        />
                                        <button
                                          type="submit"
                                          className="btn btn-outline-danger rounded-0"
                                          data-bs-dismiss="modal"
                                        >
                                          Confirmar
                                        </button>
                                      </form>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ),
            ]
          ) : (
            <div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <strong>
                Necesitas agregar un nuevo registro para ver el listado en esta
                sección!
              </strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}

          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={branches.length}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        </div>
      ) : (
        <div className="card-body">
          <ContentDeniedMessage />
        </div>
      )}
    </div>
  );
}

export default BranchesIndex;

import { createBrowserRouter } from "react-router-dom";
//Layouts
import DefaultLayout from "./Layouts/DefaultLayout.js";
import GuestLayout from "./Layouts/GuestLayout.js";

//Authentication
import Login from "./modules/Auth/Login.js";

//Dashboard
import Dashboard from "./modules/Dashboard/Dashboard";

//Products
import ProductIndex from "./modules/Products/Index";
import CreateProduct from "./modules/Products/CreateProduct";
import EditProduct from "./modules/Products/EditProduct";

//Branches
import BranchesIndex from "./modules/Branches/index";
import NewBranch from "./modules/Branches/new";
import EditBranch from "./modules/Branches/edit";

//Categories
import CategoryList from "./modules/Categories/CategoryList";
import CreateCategory from "./modules/Categories/CreateCategory";
import EditCategory from "./modules/Categories/EditCategory";

//Units
import UnitList from "./modules/Units/UnitList";
import CreateUnit from "./modules/Units/CreateUnit";
import EditUnit from "./modules/Units/EditUnit";

//Point Of sale
import PointOfSale from "./modules/POS/PointOfSale";

//Customers
import Customers from "./modules/Customers/Customers";
import CreateCustomer from "./modules/Customers/CreateCustomer";
import EditCustomer from "./modules/Customers/EditCustomer";

//Sales
import Sales from "./modules/Sales/Sales";
import NewSale from "./modules/Sales/NewSale.js";
import EditSale from "./modules/Sales/EditSale";

//Protecting Routes when is not logged in
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

//Roles
import RolesIndex from "./modules/Roles/Index";
import NewRole from "./modules/Roles/New";
import EditRole from "./modules/Roles/Edit";

//Users
import UsersIndex from "./modules/Users/Index";
import NewUser from "./modules/Users/New";
import EditUser from "./modules/Users/Edit";

//Inventories
import InventoryIndex from "./modules/Inventory/Index";
import NewInventory from "./modules/Inventory/New";
import EditInventory from "./modules/Inventory/Edit";
//Product Variant
import CreateProductVariant from "./modules/Products/Variations/Create";
import EditProductVariant from "./modules/Products/Variations/Edit";

//Not Found View
import NotFound from "./views/NotFound.js";
import SaleReports from "./modules/Sales/Reports.js";
import FinanceIndex from "./modules/Finance/Index.js";
import CreateExpenseCategory from "./modules/Finance/ExpenseCategories/CreateExpenseCategory.js";
import EditExpenseCategory from "./modules/Finance/ExpenseCategories/EditExpenseCategory.js";
import ExpenseIndex from "./modules/Finance/Expenses/Index.js";
import AddExpense from "./modules/Finance/Expenses/AddExpense.js";
import ExpenseCategoryList from "./modules/Finance/ExpenseCategories/ExpenseCategoryList.js";
import EditExpense from "./modules/Finance/Expenses/EditExpense.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      //Dashboard
      {
        path: "/",
        element: <Dashboard />,
      },
      //Point Of Sale
      {
        path: "/point-of-sale",
        element: <PointOfSale />,
      },
      {
        path: "/sales",
        element: <Sales />,
      },
      {
        path: "/sales/generate",
        element: <NewSale />,
      },
      {
        path: "/sales/edit/:saleId",
        element: <EditSale />,
      },
      {
        path: "/sales/reports",
        element: <SaleReports />,
      },
      //Categories
      {
        path: "/categories",
        element: <CategoryList />,
      },
      {
        path: "/categories/create",
        element: <CreateCategory />,
      },
      {
        path: "/categories/edit/:id",
        element: <EditCategory />,
      },
      //Products
      {
        path: "/products",
        element: <ProductIndex />,
      },
      {
        path: "/products/create",
        element: <CreateProduct />,
      },
      {
        path: "/products/edit/:id",
        element: <EditProduct />,
      },
      //Product Variant
      {
        path: "/products/create-variant/:productId",
        element: <CreateProductVariant />,
      },
      {
        path: "/products/edit-variant/:id",
        element: <EditProductVariant />,
      },
      //Customers
      {
        path: "/customers",
        element: <Customers />,
      },
      {
        path: "/customers/create",
        element: <CreateCustomer />,
      },
      {
        path: "/customers/edit/:id",
        element: <EditCustomer />,
      },
      //Branches Module
      {
        path: "/branches",
        element: <BranchesIndex />,
      },
      {
        path: "/branches/new",
        element: <NewBranch />,
      },
      {
        path: "/branches/edit/:id",
        element: <EditBranch />,
      },
      //Inventory
      {
        path: "/inventory",
        element: <InventoryIndex />,
      },
      {
        path: "/inventory/new",
        element: <NewInventory />,
      },
      {
        path: "/inventory/edit/:id",
        element: <EditInventory />,
      },
      //Roles Module
      {
        path: "/roles",
        element: <RolesIndex />,
      },
      {
        path: "/roles/new",
        element: <NewRole />,
      },
      {
        path: "/roles/edit/:id",
        element: <EditRole />,
      },
      //Finance Module
      {
        path: "/finance",
        element: <FinanceIndex />,
      },
      //Expenses Sub Module
      {
        path: "/finance/expenses",
        element: <ExpenseIndex />,
      },
      {
        path: "/finance/expenses/add",
        element: <AddExpense />,
      },
      {
        path: "/finance/expenses/edit/:id",
        element: <EditExpense />,
      },
      //Expense Categories Sub Module
      {
        path: "/finance/expenses/categories",
        element: <ExpenseCategoryList />,
      },
      {
        path: "/finance/expenses/categories/create",
        element: <CreateExpenseCategory />,
      },
      {
        path: "/finance/expenses/categories/edit/:id",
        element: <EditExpenseCategory />,
      },
      //Users Module
      {
        path: "/users",
        element: <UsersIndex />,
      },
      {
        path: "/users/new",
        element: <NewUser />,
      },
      {
        path: "/users/edit/:id",
        element: <EditUser />,
      },
    ],
  },
  {
    path: "/login",
    element: <GuestLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;

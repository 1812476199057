import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import withRouter from "../../HOC/withRouter";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

class EditCustomer extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    contact: "",
    colony: "",
    street: "",
    zip_code: "",
    description: "",
    state_id: "",
    municipality_id: "",
    states: [],
    municipalities: [],
    company_id: "",
    userId: 0,
    permissions: [],
  };

  componentDidMount() {
    const httpUrl = configUrl.uri;
    const id = this.props.params.id;

    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    this.setState({ userId: uId });
    this.setState({ permissions: currentPermissions });

    Axios.get(httpUrl + `/customers/edit/${id}`).then((res) => {
      this.setState({ name: res.data.customer.name });
      this.setState({ contact: res.data.customer.contact });
      this.setState({ phone: res.data.customer.phone });
      this.setState({ email: res.data.customer.email });
      this.setState({ state_id: res.data.customer.address.state_id });
      this.setState({
        municipality_id: res.data.customer.address.municipality_id,
      });
      this.setState({ colony: res.data.customer.address.colony });
      this.setState({ street: res.data.customer.address.street });
      this.setState({ zip_code: res.data.customer.address.zip_code });
      this.setState({ description: res.data.customer.address.description });
      this.setState({ company_id: res.data.customer.company_id });

      const states = res.data.states;
      this.setState({ states });

      this.getMunicipalities(res.data.customer.address.state_id);
    });
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  getMunicipalities = (state_id) => {
    const httpUrl = configUrl.uri;
    Axios.get(
      httpUrl + `/address/get-municipalities-by-state/${state_id}`
    ).then((res) => {
      const municipalities = res.data;
      this.setState({ municipalities });
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeState = (e) => {
    var state_id = e.target.value;
    this.getMunicipalities(state_id);
  };

  onSubmit = (e) => {
    const httpUrl = configUrl.uri;
    const id = this.props.params.id;
    const apiUrl = httpUrl + `/customers/update/${id}`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let data = new FormData();

    data.append("name", this.state.name);
    data.append("phone", this.state.phone);
    data.append("email", this.state.email);
    data.append("contact", this.state.contact);
    data.append("colony", this.state.colony);
    data.append("street", this.state.street);
    data.append("zip_code", this.state.zip_code);
    data.append("description", this.state.description);
    data.append("state_id", this.state.state_id);
    data.append("municipality_id", this.state.municipality_id);
    data.append("company_id", this.state.company_id);

    //console.log(data);

    Axios.post(apiUrl, data, config)
      .then(function (response) {
        if (response["status"] === 200) {
          alert("Cliente actualizado exitosamente.");
          setTimeout(() => {
            window.location.href = "/customers";
          }, 1500);
        }
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });

    e.preventDefault();
  };

  render() {
    return (
      <div className="card my-3">
        <div className="card-header">
          <h3 className="text-start">Editar Cliente</h3>
        </div>
        <div className="card-body">
          {this.can("edit_customers") ? (
            <form
              onSubmit={this.onSubmit}
              className="rounded py-5 px-3"
              encType="multipart/form-data"
            >
              <p className="text-dark mb-3 text-center">
                Para crear un nuevo registro por favor rellena los siguientes
                campos.
              </p>
              <div className="row">
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="name" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Nombre del negocio o persona"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.name}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="contact" className="form-label">
                    Contacto:
                  </label>
                  <input
                    type="text"
                    name="contact"
                    id="contact"
                    placeholder="Nombre de la persona a contactar"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.contact}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="phone" className="form-label">
                    Telefono:
                  </label>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    step="1"
                    min="1"
                    className="form-control mb-3"
                    placeholder="Ej: 9876543210"
                    onChange={this.onChange}
                    value={this.state.phone}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="email" className="form-label">
                    Correo:
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Correo Electrónico"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.email}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="state_id" className="form-label">
                    Estado:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="state_id"
                    id="state_id"
                    onChange={(e) => {
                      this.onChange(e);
                      this.onChangeState(e);
                    }}
                    value={this.state.state_id}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    {this.state.states.map((state, key) => (
                      <option value={state.id} key={key}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="municipality_id" className="form-label">
                    Municipio:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="municipality_id"
                    id="municipality_id"
                    onChange={this.onChange}
                    value={this.state.municipality_id}
                  >
                    {this.state.municipalities.length === 0 ? (
                      <option value="" disabled>
                        Seleccione una opcion
                      </option>
                    ) : (
                      this.state.municipalities.map((mun, key) => (
                        <option value={mun.id} key={key}>
                          {mun.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="colony" className="form-label">
                    Colonia:
                  </label>
                  <input
                    type="text"
                    name="colony"
                    id="colony"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.colony}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="street" className="form-label">
                    Calle:
                  </label>
                  <input
                    type="text"
                    name="street"
                    id="street"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.street}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 text-start">
                  <label for="zip_code" className="form-label">
                    Código Postal:
                  </label>
                  <input
                    type="number"
                    name="zip_code"
                    id="zip_code"
                    min="1"
                    step="1"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.zip_code}
                  />
                </div>
                <div className="col-12 text-start">
                  <label for="description" className="form-label">
                    Descripción:
                  </label>
                  <textarea
                    className="form-control mb-3"
                    name="description"
                    placeholder="Escribe una breve descripción sobre la dirección."
                    onChange={this.onChange}
                    value={this.state.description}
                  ></textarea>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-6">
                  <Link
                    to="/customers"
                    className="btn btn-outline-secondary rounded-0"
                  >
                    Cancelar
                  </Link>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-outline-success rounded-0"
                    type="submit"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <ContentDeniedMessage />
          )}
        </div>
      </div>
    );
  }
}

const HocEditCustomer = withRouter(EditCustomer);
export default HocEditCustomer;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { LongDateTimeFormat } from "../../../components/FormatDate";
import Pagination from "../../../components/Pagination";
import configUrl from "../../../lib/config.json";
import ContentDeniedMessage from "../../../components/ContentDeniedMessage";
import AlertMessage from "../../../components/AlertMessage";

export default class ExpenseCategoryList extends Component {
  state = {
    categories: [],
    loading: false,
    currentPage: 1,
    itemsPerPage: 10,
    userId: 0,
    permissions: [],
    companyId: 0,
  };

  componentDidMount() {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const currentPermissions = localStorage.getItem("permissions");
    this.setState({ companyId: companyId });
    this.setState({ userId: userId });
    this.setState({ permissions: currentPermissions });

    const httpUrl = configUrl.uri;
    //return <h3>Hello, {props.name}</h3>;
    axios.get(httpUrl + `/expense-categories/${companyId}`).then((res) => {
      const categories = res.data;
      this.setState({ categories });
    });
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  deleteRecord = (e, id = null) => {
    const httpUrl = configUrl.uri;
    if (id) {
      console.log(id);
      axios
        .delete(httpUrl + `/expense-categories/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            this.componentDidMount();
            alert("Registro eliminado correctamente");
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  render() {
    const { categories, loading, currentPage, itemsPerPage } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = categories.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNum) => this.setState({ currentPage: pageNum });
    const nextPage = () => this.setState({ currentPage: currentPage + 1 });
    const prevPage = () => this.setState({ currentPage: currentPage - 1 });
    return (
      <div className="my-3 px-lg-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/finance">Finanzas</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/finance/expenses">Gastos</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Categorías de Gastos
            </li>
          </ol>
        </nav>
        <div className="row mb-3">
          <div className="col-12 col-md-6 my-auto">
            <h2 className="text-center text-md-start mb-3 mb-md-0">
              Categorías de Gastos
            </h2>
          </div>
          <div className="col-12 col-md-6 my-auto text-center text-md-end">
            {this.can("create_expense_categories") ? (
              <Link
                to="/finance/expenses/categories/create"
                className="btn btn-outline-primary rounded-0"
              >
                Crear Categoría de Gastos <i className="fas fa-plus"></i>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="card shadow my-3">
          {this.can("expense_cat_index") ? (
            <div className="card-body">
              {loading ? (
                <div className="alert alert-primary" role="alert">
                  Cargando...
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Nombre</th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          Opciones
                        </th>
                      </tr>
                    </thead>
                    {currentItems.length > 0 ? (
                      <tbody>
                        {currentItems.map((cat, key) => (
                          <tr key={key}>
                            <td>{cat.id}</td>
                            <td>{cat.name}</td>
                            <td style={{ minWidth: "100px" }}>
                              {this.can("edit_expense_categories") ? (
                                <Link
                                  to={`/finance/expenses/categories/edit/${cat.id}`}
                                  className="btn btn-primary btn-sm rounded-0 mx-1"
                                  title={`Editar ${cat.name}`}
                                >
                                  <i className="fas fa-pen"></i>
                                </Link>
                              ) : (
                                ""
                              )}
                              {this.can("delete_expense_categories") ? (
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm rounded-0 mx-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#confirmRecordDeletion-${cat.id}`}
                                  title={`Eliminar ${cat.name}`}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              ) : (
                                ""
                              )}
                              <div
                                className="modal fade"
                                id={`confirmRecordDeletion-${cat.id}`}
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex="-1"
                                aria-labelledby="confirmRecordDeletionLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="confirmRecordDeletionLabel"
                                      >
                                        Atención!
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        <div className="col-12 text-center">
                                          <p className="text-danger fw-bold">
                                            ¿Esta seguro de eliminar este
                                            registro?
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-12 text-center">
                                        <p className="mb-0">
                                          <b>Nombre:</b>
                                        </p>
                                        <p>{cat.name}</p>
                                      </div>
                                      <div className="col-12 text-center">
                                        <p className="mb-0">
                                          <b>Creado:</b>
                                        </p>
                                        <p>
                                          <LongDateTimeFormat
                                            date={cat.created_at}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary rounded-0"
                                        data-bs-dismiss="modal"
                                      >
                                        Cancelar
                                      </button>
                                      {this.can("delete_expense_categories") ? (
                                        <form
                                          onSubmit={(e) =>
                                            this.deleteRecord(e, cat.id)
                                          }
                                        >
                                          <input
                                            type="hidden"
                                            name="id"
                                            value={cat.id}
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-outline-danger rounded-0"
                                            data-bs-dismiss="modal"
                                          >
                                            Confirmar
                                          </button>
                                        </form>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={"6"}>
                            <AlertMessage
                              type={`warning`}
                              message={`No se encotraron registros de Categorias.`}
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={categories.length}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
              />
            </div>
          ) : (
            <div className="card-body">
              <ContentDeniedMessage />
            </div>
          )}
        </div>
      </div>
    );
  }
}

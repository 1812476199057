import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import configUrl from "../../../lib/config.json";
import ContentDeniedMessage from "../../../components/ContentDeniedMessage";

export default function EditExpense() {  
    //Record ID
  const { id } = useParams();
  //Records to be displayed on each select field.
  const [branches, setBranches] = useState([]);
  const [categories, setCategories] = useState([]);
  //Variables to be handled by the form.
  const [branchId, setBranchId] = useState(0);
  const [expenseCategoryId, setExpenseCategoryId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState("");
  const [permissions, setPermissions] = useState([]);

  const httpUrl = configUrl.uri;

  const getData = async () => {
    const currentPermissions = localStorage.getItem("permissions");
    setPermissions(await currentPermissions);

    const res = await Axios.get(httpUrl + `/expenses/edit/${id}`);

    setBranches(res.data.branches);
    setCategories(res.data.expenseCategories);

    setBranchId(res.data.expense.branch_id);
    setExpenseCategoryId(res.data.expense.expense_category_id);
    setName(res.data.expense.name);
    setDescription(res.data.expense.description);
    setAmount(res.data.expense.amount);
    setDate(res.data.expense.date);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const saveRecord = async (e) => {
    const apiUrl = httpUrl + `/expenses/update/${id}`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let data = new FormData();
    data.append("branch_id", branchId);
    data.append("expense_category_id", expenseCategoryId);
    data.append("name", name);
    data.append("description", description);
    data.append("amount", amount);
    data.append("date", date);

    //Sending data to the api to save the record
    Axios.post(apiUrl, data, config)
      .then(function (res) {
        //console.log(res);
        if (res["status"] === 200) {
          alert("Registro actualizado exitosamente.");
          setTimeout(() => {
            window.location.href = "/finance/expenses";
          }, 2000);
        }
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });
  };

  return (
    <div className="my-3 px-lg-3">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/finance">Finanzas</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/finance/expenses">Gastos</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Editar Gasto - {name}
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col-12 my-auto text-md-start">
          <h2>Editar Gasto - {name}</h2>
        </div>
      </div>
      <div className="card my-3 shadow">
        <div className="card-body">
          {can("edit_expenses") ? (
            <form className="row justify-content-center justify-content-md-start rounded py-5 px-3">
              <p className="text-dark mb-3 text-center">
                Para crear un nuevo registro por favor rellena los siguientes
                campos.
              </p>
              <div className="col-12 col-sm-6 col-xl-4 text-start">
                <label htmlFor="name" className="form-label">
                  Nombre:
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nombre"
                  className="form-control mb-3"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="col-12 col-sm-6 col-xl-4 text-start">
                <label htmlFor="branchId" className="form-label">
                  Sucursal:
                </label>
                <select
                  className="form-select mb-3"
                  name="branchId"
                  id="branchId"
                  onChange={(e) => {
                    setBranchId(e.target.value);
                  }}
                  value={branchId}
                >
                  <option value="0" disabled>
                    Selecciona una opción
                  </option>
                  {branches.map((branch, key) => (
                    <option value={branch.id} key={key}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 col-xl-4 text-start">
                <label htmlFor="expenseCategoryId" className="form-label">
                  Categorías:
                </label>
                <select
                  className="form-select mb-3"
                  name="expenseCategoryId"
                  id="expenseCategoryId"
                  onChange={(e) => setExpenseCategoryId(e.target.value)}
                  value={expenseCategoryId}
                >
                  <option value="0" disabled>
                    Seleccione una opcion
                  </option>
                  {categories.map((cat, key) => (
                    <option value={cat.id} key={key}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 col-xl-4 text-start">
                <label htmlFor="amount" className="form-label">
                  Monto:
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  step="0.01"
                  className="form-control mb-3"
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                />
              </div>
              <div className="col-12 col-sm-6 col-xl-4 text-start">
                <label htmlFor="date" className="form-label">
                  Fecha:
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  className="form-control mb-3"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                />
              </div>
              <div className="col-12 text-start">
                <label htmlFor="description" className="form-label">
                  Descripción:
                </label>
                <textarea
                  className="form-control mb-3"
                  name="description"
                  placeholder="Escribe una breve descripción sobre la dirección."
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
              <div className="col-6 text-center">
                <Link
                  to="/finance/expenses"
                  className="btn btn-outline-secondary rounded-0"
                >
                  Cancelar
                </Link>
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn btn-outline-success rounded-0"
                  type="button"
                  onClick={(e) => saveRecord(e)}
                >
                  Guardar
                </button>
              </div>
            </form>
          ) : (
            <ContentDeniedMessage />
          )}
        </div>
      </div>
    </div>
  );
}

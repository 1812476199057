import React, { useEffect, useState } from "react";

export default function SaleStatus(props) {
  const [status, setStatus] = useState(0);
  const [statusBg, setStatusBg] = useState("");

  const showCurrentStatus = (props) => {
    setStatus(props.status);

    if (props.status === 0) {
      setStatus("No Especificado");
      setStatusBg("bg-light text-secondary");
    }

    if (props.status === 1) {
      setStatus("Pendiente");
      setStatusBg("bg-info text-dark");
    }

    if (props.status === 2) {
      setStatus("En Proceso");
      setStatusBg("bg-primary");
    }

    if (props.status === 3) {
      setStatus("Completado");
      setStatusBg("bg-success");
    }

    if (props.status === 4) {
      setStatus("Enviado");
      setStatusBg("bg-light");
    }

    if (props.status === 5) {
      setStatus("Entregado");
      setStatusBg("bg-success");
    }

    if (props.status === 6) {
      setStatus("En Espera");
      setStatusBg("bg-secondary");
    }

    if (props.status === 7) {
      setStatus("Regresado");
      setStatusBg("bg-danger");
    }

    if (props.status === 8) {
      setStatus("Parcialmente Entregado");
      setStatusBg("bg-warning text-dark");
    }

    if (props.status === 9) {
      setStatus("Esperando Pago");
      setStatusBg("bg-warning text-dark");
    }

    if (props.status === 10) {
      setStatus("Cancelado");
      setStatusBg("bg-danger");
    }
  };

  useEffect(() => {
    showCurrentStatus(props);
  });

  return <span className={`badge rounded-pill ${statusBg}`}>{status}</span>;
}

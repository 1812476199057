import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import config from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

function EditRole() {
  const [permissions, setPermissions] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [name, setName] = useState();
  const [status, setStatus] = useState();

  const [userId, setUserId] = useState(0);
  const [activePermissions, setActivePermissions] = useState([]);

  const httpUrl = config.uri;

  const http = Axios.create({
    baseURL: config.baseUrl,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });

  const httpUser = Axios.create({
    baseURL: config.baseUrl,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    },
    withCredentials: true,
  });

  const getUser = async () => {
    const user = await httpUser.get("/api/user");
    const activePermissions = await http.get(
      `/api/users/permissions/${user.data.id}`
    );
    //console.log("user: ", user.data);
    setUserId(user.data.id);
    setActivePermissions(await activePermissions.data);
  };

  const can = (permission) => {
    if (activePermissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  //Record ID
  const { id } = useParams();

  const getData = async () => {
    const apiUrl = httpUrl + `/roles/edit/${id}`;
    const res = await Axios.get(apiUrl);
    console.log("Edit Rol Data: ", res);
    setPermissions(await res.data.permissions);
    setName(await res.data.role.name);
    setStatus(await res.data.role.status);
    setCurrentPermissions(
      await currentPermissions.concat(res.data.rolePermissions)
    );
    //console.log("List of Permissions: ", permissions);
    console.log("CurrentPermissions: ", currentPermissions);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCheckBoxes = async (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      //console.log("Activado");
      if (!currentPermissions.includes(parseInt(e.target.value))) {
        currentPermissions.push(parseInt(e.target.value));
        setCurrentPermissions(currentPermissions);
      }
    } else {
      //console.log("Desactivado");
      if (currentPermissions.includes(parseInt(e.target.value))) {
        const index = currentPermissions.indexOf(parseInt(e.target.value));
        currentPermissions.splice(index, 1);
        setCurrentPermissions(currentPermissions);
      }
    }
    //console.log("Is Checked: ", isChecked);
    console.log("New Permissions: ", currentPermissions);
  };

  const updateRecord = async (e) => {
    const apiUrl = httpUrl + `/roles/update/${id}`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let data = new FormData();
    data.append("name", name);
    data.append("status", status);
    data.append("permissions", [currentPermissions]);

    //Sending data to the api to save the record
    Axios.post(apiUrl, data, config)
      .then(function (res) {
        console.log(res);
        if (res["status"] === 200) {
          alert("Registro actualizado exitosamente.");
          setTimeout(() => {
            window.location.href = "/roles";
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });
  };

  return (
    <div className="View">
      <Navbar permissions={permissions} />
      <div className="container-fluid content">
        <div className="row">
          <div className="col-12 col-lg-3 col-xl-2 px-0 d-none d-lg-block">
            <Sidebar userId={userId} permissions={permissions}></Sidebar>
          </div>
          <div className="col-12 col-lg-9 col-xl-10 bg-light">
            <div className="card my-3">
              <div className="card-header">
                <h3 className="text-start">Editar Rol de Usuario</h3>
              </div>
              <div className="card-body">
                {can("edit_roles") ? (
                  <form className="row justify-content-center rounded py-5 px-3">
                    <p className="text-dark mb-3 text-center">
                      Cambie el valor de un campo y despues haga click en
                      guardar para actualizar el registro.
                    </p>
                    <div className="col-12 col-lg-6 text-start">
                      <label htmlFor="name" className="form-label">
                        Nombre:
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nombre"
                        className="form-control mb-3"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                    <div className="col-12 col-lg-6 text-start mb-5">
                      <label htmlFor="status" className="form-label">
                        Estado:
                      </label>
                      <select
                        className="form-select mb-3"
                        name="status"
                        id="status"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                      >
                        <option value="">Selecciona una opción</option>
                        <option value="1">Activado</option>
                        <option value="0">Desactivado</option>
                      </select>
                    </div>
                    <div className="col-12 my-3">
                      <h5 className="h5 text-center font-weight-bold">
                        Lista de Permisos
                      </h5>
                    </div>
                    <div className="container-fluid mb-5">
                      <div className="row">
                        {permissions ? (
                          permissions.map((permission, index) => (
                            <div
                              className="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 mb-3"
                              key={index}
                            >
                              <div className="form-group form-check text-start">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={"permission-" + permission.id}
                                  value={permission.id}
                                  defaultChecked={currentPermissions.find(
                                    (itemInArray) =>
                                      permission.id === itemInArray
                                  )}
                                  onClick={(e) => handleCheckBoxes(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"permission-" + permission.id}
                                >
                                  {permission.description}
                                </label>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            className="alert alert-warning alert-dismissible fade show"
                            role="alert"
                          >
                            No ha sido posible mostrar los permisos.
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/roles"
                        className="btn btn-outline-secondary rounded-0"
                      >
                        Cancelar
                      </Link>
                    </div>
                    <div className="col-6">
                      <button
                        className="btn btn-outline-success rounded-0"
                        type="button"
                        onClick={(e) => updateRecord(e)}
                      >
                        Guardar
                      </button>
                    </div>
                  </form>
                ) : (
                  <ContentDeniedMessage />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRole;

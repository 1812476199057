import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link, useParams } from "react-router-dom";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";
import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AlertMessage from "../../components/AlertMessage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export default function SaleReports() {
  const [permissions, setPermissions] = useState([]);
  const [branches, setBranches] = useState([]);
  //Range date variables
  const [salesRevenueStartDate, setSalesRevenueStartDate] = useState("");
  const [salesRevenueEndDate, setSalesRevenueEndDate] = useState("");
  const [saleRevenueBranch, setSaleRevenueBranch] = useState(0);
  const [quantityOfProductsSoldStartDate, setQuantityOfProductsSoldStartDate] =
    useState("");
  const [quantityOfProductsSoldEndDate, setQuantityOfProductsSoldEndDate] =
    useState("");
  //These variables are for teh Sale Revenue by date range chart
  const [isActiveDateRangeBtn, setIsActiveDateRangeBtn] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const [displayDateRangeAlert, setDisplayDateRangeAlert] = useState(false);
  //These variables are for teh Quantity of Product Sold by date range chart
  const [isActiveDateRangeBtn2, setIsActiveDateRangeBtn2] = useState(false);
  const [alertText2, setAlertText2] = useState("");
  const [alertType2, setAlertType2] = useState("");
  const [displayDateRangeAlert2, setDisplayDateRangeAlert2] = useState(false);
  //Sales of Days By Date Range.
  const [saleDaysByDateRange, setSaleDaysByDateRange] = useState([]);
  const [saleIncomeByDateRange, setSaleIncomeByDateRange] = useState([]);
  //Money Earned of each Month on the year.
  const [monthlySales, setMonthlySales] = useState([]);

  //Quantity of Product Sold variables
  const [qopsData, setQopsData] = useState({});
  const [qopsOptions, setQopsOptions] = useState({});

  const getData = async () => {
    const currentPermissions = localStorage.getItem("permissions");
    setPermissions(await currentPermissions);

    const httpUrl = configUrl.uri;

    const uId = localStorage.getItem("userId");
    const companyId = localStorage.getItem("companyId");

    //Get Company Branches
    const companyBranches = await Axios.get(`${httpUrl}/branches/${uId}`);

    setBranches(companyBranches.data);

    //Get Money earned by month on the current year
    const res = await Axios.get(
      `${httpUrl}/sales/get-monthly-sales-data/${companyId}`
    );

    getMonthlySales(res.data.sales);
    //setmonthlySalesIncome(await res.data.incomes);

    //console.log("Sales Branches:", companyBranches);
  };

  const getMonthlySales = (data) => {
    const labels = data.map((item) => item.month);
    const revenue = data.map((item) => item.income);

    setMonthlySales({
      labels: labels,
      datasets: [
        {
          label: "Ventas Mensuales",
          data: revenue,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
        },
      ],
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const getSalesRevenueStartDate = async (e) => {
    setSalesRevenueStartDate(e.target.value);
    activateDateRangeBtn();
    //console.log("Start Date", e.target.value);
    e.preventDefault();
  };

  const getSalesRevenueEndDate = async (e) => {
    setSalesRevenueEndDate(e.target.value);
    activateDateRangeBtn();
    //console.log("End Date", e.target.value);
    e.preventDefault();
  };

  const checkSalesByDateRange = (e) => {
    if (salesRevenueEndDate >= salesRevenueStartDate) {
      setDisplayDateRangeAlert(false);
      getSalesByDateRange();
    } else {
      setAlertType("danger");
      setAlertText(
        "La Fecha de Finalizacion debe de ser mayor a la Fecha de Inicio"
      );
      setDisplayDateRangeAlert(true);
    }

    e.preventDefault();
  };

  const activateDateRangeBtn = () => {
    if (salesRevenueStartDate === "" && salesRevenueEndDate === "") {
      setIsActiveDateRangeBtn(false);
    } else {
      setIsActiveDateRangeBtn(true);
    }
  };

  useEffect(() => {
    activateDateRangeBtn();
  }, []);

  const getSalesByDateRange = async () => {
    const httpUrl = configUrl.uri;

    let data = new FormData();
    data.append("companyId", localStorage.getItem("companyId"));
    data.append("branchId", saleRevenueBranch);
    data.append("startDate", salesRevenueStartDate);
    data.append("endDate", salesRevenueEndDate);

    const res = await Axios.post(
      `${httpUrl}/sales/get-money-earned-by-date-range`,
      data
    );

    setSaleDaysByDateRange(await res.data.days);
    setSaleIncomeByDateRange(await res.data.incomes);

    console.log("Sales By Date Range:", res);
  };

  //This is for Quantity of product Sold chart
  const getQuantityOfProductsSoldStartDate = async (e) => {
    setQuantityOfProductsSoldStartDate(e.target.value);
    activateDateRangeBtn2();
    //console.log("QOPS Start Date", e.target.value);
    e.preventDefault();
  };

  const getQuantityOfProductsSoldEndDate = async (e) => {
    setQuantityOfProductsSoldEndDate(e.target.value);
    activateDateRangeBtn2();
    //console.log("QOPS End Date", e.target.value);
    e.preventDefault();
  };

  const checkSalesByDateRange2 = (e) => {
    if (quantityOfProductsSoldEndDate >= quantityOfProductsSoldStartDate) {
      setDisplayDateRangeAlert2(false);
      getQuantityOfProductSoldByDateRange();
    } else {
      setAlertType2("danger");
      setAlertText2(
        "La Fecha de Finalizacion debe de ser mayor a la Fecha de Inicio"
      );
      setDisplayDateRangeAlert2(true);
    }

    e.preventDefault();
  };

  const activateDateRangeBtn2 = () => {
    if (
      quantityOfProductsSoldStartDate === "" &&
      quantityOfProductsSoldEndDate === ""
    ) {
      setIsActiveDateRangeBtn2(false);
    } else {
      setIsActiveDateRangeBtn2(true);
    }
  };

  useEffect(() => {
    activateDateRangeBtn2();
  }, []);

  // Generate a random color
  const getRandomColor = () => {
    return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)})`;
  };

  const getQuantityOfProductSoldByDateRange = async () => {
    const httpUrl = configUrl.uri;

    let data = new FormData();
    data.append("companyId", localStorage.getItem("companyId"));
    data.append("startDate", quantityOfProductsSoldStartDate);
    data.append("endDate", quantityOfProductsSoldEndDate);

    const res = await Axios.post(
      `${httpUrl}/sales/get-quantity-of-products-sold-by-date-range`,
      data
    );

    console.log("Quantity Chart", res);

    // Group products by type
    const groupedProducts = {};

    res.data.products.forEach((product) => {
      if (groupedProducts[product.name]) {
        groupedProducts[product.name].push(product);
      } else {
        groupedProducts[product.name] = [product];
      }
    });

    console.log("Grouped Products", groupedProducts);

    const uniqueDates = [
      ...new Set(res.data.products.flat().map((item) => item.date)),
    ];

    console.log("Unique Dates:", uniqueDates);

    // Generate datasets for each type of product
    const datasets = Object.entries(groupedProducts).map(
      ([name, productArray]) => {
        const arrayData = productArray.map((product) => ({
          x: product.date,
          y: product.quantity,
        }));

        return {
          label: name,
          data: arrayData,
          fill: false,
          borderColor: getRandomColor(),
        };
      }
    );

    console.log("Datasets", datasets);

    // Define the chart data
    const chartData = {
      labels: uniqueDates,
      datasets: datasets,
    };

    // Define the chart options
    const options = {
      maintainAspectRatio: false,
    };

    setQopsData(chartData);
    setQopsOptions(options);
  };

  return (
    <div className="mt-3">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/sales">Ventas</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Reportes
          </li>
        </ol>
      </nav>
      <h3 className="text-center text-md-start">Reportes de Venta</h3>
      {can("sale_reports") ? (
        <div>
          {/* Income of Sales on The Current Year */}
          <div className="card mt-3 mb-5 shadow">
            <div className="card-body">
              <div className="row justify-content-center mb-5">
                <div className="col-12">
                  <h5 className="text-center mb-4">
                    Ingresos de Ventas en el Año Actual
                  </h5>
                  <p className="text-center">
                    Los datos que se muestran en la siguiente grafica muestran
                    informacion acerca del las ganancias obtenidas por mes en el
                    año actual.
                  </p>
                </div>
                <div className="col-12">
                  {Object.keys(monthlySales).length > 0 ? (
                    <Line
                      data={monthlySales}
                      options={{ maintainAspectRatio: false }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Income of sales by date range */}
          <div className="card mt-3 mb-5 shadow">
            <div className="card-body">
              <div className="row justify-content-center justify-content-md-start mb-3">
                <div className="col-12">
                  <h5 className="text-center mb-4">
                    Ingresos de Ventas por Rango de Fechas
                  </h5>
                  <p className="text-center">
                    Selecciona el rango de fechas para consultar las ventas
                    realizadas en un rango de especificado.
                  </p>
                </div>
                <div
                  className={`col-12 ${
                    displayDateRangeAlert ? "d-block" : "d-none"
                  }`}
                >
                  <AlertMessage type={alertType} message={alertText} />
                </div>
                <label
                  htmlFor="salesRevenueStartDate"
                  className="col-5 col-md-3 col-lg-2 col-form-label mb-3"
                >
                  Fecha de Inicio:
                </label>
                <div className="col-7 col-md-3 col-lg-4 my-auto">
                  <input
                    type="date"
                    name="salesRevenueStartDate"
                    id="salesRevenueStartDate"
                    className="form-control mb-3"
                    onChange={(e) => getSalesRevenueStartDate(e)}
                    value={salesRevenueStartDate}
                  />
                </div>
                <label
                  htmlFor="salesRevenueEndDate"
                  className="col-5 col-md-3 col-lg-2 col-form-label mb-3"
                >
                  Fecha de Finalización:
                </label>
                <div className="col-7 col-md-3 col-lg-4 my-auto">
                  <input
                    type="date"
                    name="salesRevenueEndDate"
                    id="salesRevenueEndDate"
                    className="form-control mb-3"
                    onChange={(e) => getSalesRevenueEndDate(e)}
                    value={salesRevenueEndDate}
                  />
                </div>
                <label
                  htmlFor="saleRevenueBranch"
                  className="col-5 col-md-3 col-lg-2 col-form-label mb-3"
                >
                  Sucursal:
                </label>
                <div className="col-12 col-md-3 col-lg-4 my-auto">
                  <select
                    className="form-select mb-3"
                    name="saleRevenueBranch"
                    id="saleRevenueBranch"
                    onChange={(e) => {
                      setSaleRevenueBranch(e.target.value);
                    }}
                    value={saleRevenueBranch}
                  >
                    <option value="0" disabled>
                      Selecciona una opción
                    </option>
                    {branches.map((branch, key) => (
                      <option value={branch.id} key={key}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary rounded-0 sales-by-date-range-btn mb-3"
                    onClick={(e) => checkSalesByDateRange(e)}
                    id="salesByDateRangeBtn"
                    disabled={isActiveDateRangeBtn === true ? false : true}
                  >
                    Consultar
                  </button>
                </div>
                <div className="col-12">
                  {saleDaysByDateRange.length > 0 &&
                  saleIncomeByDateRange.length > 0 ? (
                    <Line
                      data={{
                        labels: saleDaysByDateRange,
                        datasets: [
                          {
                            label: "Ventas por Dia",
                            data: saleIncomeByDateRange,
                            backgroundColor: "rgba(54, 162, 235, 0.2)",
                            borderColor: "rgba(54, 162, 235, 1)",
                          },
                        ],
                      }}
                      options={{ maintainAspectRatio: false }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Quantity of product sold by date range */}
          <div className="card mt-3 mb-5 shadow">
            <div className="card-body">
              <div className="row justify-content-center mb-3">
                <div className="col-12">
                  <h5 className="text-center mb-4">
                    Cantidad de Productos vendidos por rango de fechas
                  </h5>
                  <p className="text-center">
                    Selecciona un rango de fechas para visualizar la cantidad de
                    productos vendidos en un tiempo determinado.
                  </p>
                </div>
                <div
                  className={`col-12 ${
                    displayDateRangeAlert2 ? "d-block" : "d-none"
                  }`}
                >
                  <AlertMessage type={alertType2} message={alertText2} />
                </div>
                <label
                  htmlFor="salesRevenueStartDate"
                  className="col-5 col-md-3 col-lg-2 col-form-label mb-3"
                >
                  Fecha de Inicio:
                </label>
                <div className="col-7 col-md-3 col-lg-4 my-auto">
                  <input
                    type="date"
                    name="salesRevenueStartDate"
                    id="salesRevenueStartDate"
                    className="form-control mb-3"
                    onChange={(e) => getQuantityOfProductsSoldStartDate(e)}
                    value={quantityOfProductsSoldStartDate}
                  />
                </div>
                <label
                  htmlFor="salesRevenueEndDate"
                  className="col-5 col-md-3 col-lg-2 col-form-label mb-3"
                >
                  Fecha de Finalización:
                </label>
                <div className="col-7 col-md-3 col-lg-4 my-auto">
                  <input
                    type="date"
                    name="quantityOfProductsSoldEndDate"
                    id="quantityOfProductsSoldEndDate"
                    className="form-control mb-3"
                    onChange={(e) => getQuantityOfProductsSoldEndDate(e)}
                    value={quantityOfProductsSoldEndDate}
                  />
                </div>
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary rounded-0 quantity-of-products-sold-by-date-range-btn mb-3"
                    onClick={(e) => checkSalesByDateRange2(e)}
                    id="quantityOfProductsSoldByDateRangeBtn"
                    disabled={isActiveDateRangeBtn2 === true ? false : true}
                  >
                    Consultar
                  </button>
                </div>
                <div className="col-12">
                  {Object.keys(qopsData).length > 0 ? (
                    <Line data={qopsData} options={qopsOptions} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card-body">
          <ContentDeniedMessage />
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useStateContext } from "../contexts/ContextProvider";

export default function DefaultLayout() {
  const { user, token } = useStateContext();
  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const getData = async () => {
    setUserId(await parseInt(localStorage.getItem("userId")));
    setPermissions(await JSON.stringify(localStorage.getItem("permissions")));
  };

  useEffect(() => {
    getData();
  }, []);

  //Validating User's login
  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="View">
      <Navbar permissions={permissions} />
      <div className="container-fluid content">
        <div className="row">
          <div className="col-12 col-lg-3 col-xl-2 px-0 d-none d-lg-block">
            <Sidebar userId={userId} permissions={permissions} />
          </div>
          <div className="col-12 col-lg-9 col-xl-10 bg-light">
            <div className="row">
              <div className="col-12">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { NavLink } from "react-router-dom";

export default function Sidebar(props) {
  const can = (permission) => {
    if (props.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="card h-100 rounded-0 border-0 shadow">
      <div className="card-body">
        <div
          className="nav flex-column nav-pills sidebar"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {can("dashboard") ? (
            <NavLink to="/" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Dashboard</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("point_of_sale") ? (
            <NavLink to="/point-of-sale" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Punto de Venta</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-cash-register"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("sales_index") ? (
            <NavLink to="/sales" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Ventas</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-coins"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("categories_index") ? (
            <NavLink to="/categories" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Categorias</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-stream"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("products_index") ? (
            <NavLink to="/products" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Productos</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-boxes"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("customers_index") ? (
            <NavLink to="/customers" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Clientes</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-users"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("branches_index") ? (
            <NavLink to="/branches" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Sucursales</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-project-diagram"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("inventories_index") ? (
            <NavLink to="/inventory" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Inventario</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-warehouse"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("finance_index") ? (
            <NavLink to="/finance" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Finanzas</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-hand-holding-usd"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {can("users_index") ? (
            <NavLink to="/users" className="nav-link text-start w-100">
              <div className="row">
                <div className="col-9">Usuarios</div>
                <div className="col-3 my-auto text-center">
                  <i className="fas fa-users"></i>
                </div>
              </div>
            </NavLink>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

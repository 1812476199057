import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

function NewBranch() {
  //Data to be display on each select
  //const [companies, setCompanies] = useState([]);
  const [states, setStates] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);

  //Variables to save each data on the form.
  const [name, setName] = useState();
  const [company_id, setCompanyId] = useState(0);
  const [state_id, setStateId] = useState(0);
  const [municipality_id, setMunicipalityId] = useState(0);
  const [colony, setColony] = useState();
  const [street, setStreet] = useState();
  const [zip_code, setZipCode] = useState();
  const [description, setDescription] = useState();

  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const httpUrl = configUrl.uri;

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);

    const res = await Axios.get(httpUrl + `/branches/create/${uId}`);
    //console.log("branch data: ", res);
    setCompanyId(res.data.company_id);
    setStates(res.data.states);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeState = async (value) => {
    const httpUrl = configUrl.uri;
    Axios.get(httpUrl + `/address/get-municipalities-by-state/${value}`).then(
      (res) => {
        //console.log(res);
        setMunicipalities(res.data);
      }
    );
  };

  const saveRecord = async (e) => {
    const apiUrl = httpUrl + "/branches/save";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let data = new FormData();
    data.append("name", name);
    data.append("company_id", company_id);
    data.append("state_id", state_id);
    data.append("municipality_id", municipality_id);
    data.append("colony", colony);
    data.append("street", street);
    data.append("zip_code", zip_code);
    data.append("description", description);

    //Sending data to the api to save the record
    Axios.post(apiUrl, data, config)
      .then(function (res) {
        //console.log(res);
        if (res["status"] === 200) {
          alert("Registro guardado exitosamente.");
          setTimeout(() => {
            window.location.href = "/branches";
          }, 2000);
        }
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });
  };

  return (
    <div className="card my-3">
      <div className="card-header">
        <h3 className="text-start">Nueva Sucursal</h3>
      </div>
      <div className="card-body">
        {can("add_branches") ? (
          <form className="row justify-content-center rounded py-5 px-3">
            <p className="text-dark mb-3 text-center">
              Para crear un nuevo registro por favor rellena los siguientes
              campos.
            </p>
            <div className="col-12 col-sm-6 col-xl-4 text-start">
              <label htmlFor="name" className="form-label">
                Nombre:
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Nombre"
                className="form-control mb-3"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-4 text-start">
              <label htmlFor="state_id" className="form-label">
                Estado:
              </label>
              <select
                className="form-select mb-3"
                name="state_id"
                id="state_id"
                onChange={(e) => {
                  setStateId(e.target.value);
                  onChangeState(e.target.value);
                }}
                value={state_id}
              >
                <option value="0" disabled>
                  Selecciona una opción
                </option>
                {states.map((state, key) => (
                  <option value={state.id} key={key}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 text-start">
              <label htmlFor="municipality_id" className="form-label">
                Municipio:
              </label>
              <select
                className="form-select mb-3"
                name="municipality_id"
                id="municipality_id"
                onChange={(e) => setMunicipalityId(e.target.value)}
                value={municipality_id}
              >
                <option value="0" disabled>
                  Seleccione una opcion
                </option>
                {municipalities.map((mun, key) => (
                  <option value={mun.id} key={key}>
                    {mun.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 text-start">
              <label htmlFor="colony" className="form-label">
                Colonia:
              </label>
              <input
                type="text"
                name="colony"
                id="colony"
                className="form-control mb-3"
                onChange={(e) => setColony(e.target.value)}
                value={colony}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-4 text-start">
              <label htmlFor="street" className="form-label">
                Calle:
              </label>
              <input
                type="text"
                name="street"
                id="street"
                className="form-control mb-3"
                onChange={(e) => setStreet(e.target.value)}
                value={street}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-4 text-start">
              <label htmlFor="zip_code" className="form-label">
                Código Postal:
              </label>
              <input
                type="number"
                name="zip_code"
                id="zip_code"
                min="1"
                step="1"
                placeholder="XXXXX"
                className="form-control mb-3"
                onChange={(e) => setZipCode(e.target.value)}
                value={zip_code}
              />
            </div>
            <div className="col-12 text-start">
              <label htmlFor="description" className="form-label">
                Descripción:
              </label>
              <textarea
                className="form-control mb-3"
                name="description"
                placeholder="Escribe una breve descripción sobre la dirección."
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              ></textarea>
            </div>
            <div className="col-6">
              <Link
                to="/branches"
                className="btn btn-outline-secondary rounded-0"
              >
                Cancelar
              </Link>
            </div>
            <div className="col-6">
              <button
                className="btn btn-outline-success rounded-0"
                type="button"
                onClick={(e) => saveRecord(e)}
              >
                Guardar
              </button>
            </div>
          </form>
        ) : (
          <ContentDeniedMessage />
        )}
      </div>
    </div>
  );
}

export default NewBranch;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import configFile from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";
export default class CreateProduct extends Component {
  state = {
    name: "",
    image: null,
    description: "",
    price: "",
    useGeneralPrice: 0,
    status: 1,
    category_id: "",
    unit_id: "",
    categories: [],
    units: [],
    userId: 0,
    company_id: "",
    permissions: [],
    generalPrice: false,
  };

  componentDidMount() {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    this.setState({ userId: uId });
    this.setState({ permissions: currentPermissions });
    this.fetchRecords(uId);
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  fetchRecords = (userId) => {
    Axios.get(configFile.uri + `/products/create/${userId}`).then((res) => {
      this.setState({ company_id: res.data.company_id });
      this.setState({ categories: res.data.categories });
      this.setState({ units: res.data.units });
    });
  };

  onSubmit = (e) => {
    console.log(this.state);
    const httpUrl = configFile.uri;
    const apiUrl = httpUrl + "/products/save";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let product = new FormData();

    product.append("name", this.state.name);
    product.append("image", this.state.image);
    product.append("price", this.state.price);
    product.append("use_general_price", this.state.useGeneralPrice);
    product.append("status", this.state.status);
    product.append("category_id", this.state.category_id);
    product.append("unit_id", this.state.unit_id);
    product.append("company_id", this.state.company_id);
    product.append("description", this.state.description);

    Axios.post(apiUrl, product, config)
      .then(function (response) {
        if (response["status"] === 200) {
          alert("Producto creado exitosamente.");
          setTimeout(() => {
            window.location.href = "/products";
          }, 1500);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });

    e.preventDefault();
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeGeneralPrice = (e) => {
    var isChecked = e.target.checked;
    this.setState({
      [e.target.name]: e.target.checked,
    });
    if (isChecked === false) {
      this.setState({ price: "" });
      this.setState({ useGeneralPrice: 0 });
    } else {
      this.setState({ useGeneralPrice: 1 });
    }
  };

  onChangeFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  render() {
    return (
      <div className="mt-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/products">Productos</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nuevo Producto
            </li>
          </ol>
        </nav>
        <div className="row mb-3">
          <div className="col-12 col-sm-6 my-auto">
            <h3 className="text-center text-sm-start mb-3 mb-sm-0">
              Nuevo Producto
            </h3>
          </div>
        </div>
        <div className="card my-3 border-0 shadow">
          {this.can("create_products") ? (
            <div className="card-body">
              <form
                onSubmit={this.onSubmit}
                className="row justify-content-center rounded py-5 px-3"
                encType="multipart/form-data"
              >
                <p className="text-dark mb-3 text-center">
                  Para crear un nuevo registro por favor rellena los siguientes
                  campos.
                </p>
                <div className="col-12 col-md-6 col-xxl-4 text-start">
                  <label htmlFor="name" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Nombre"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.name}
                  />
                </div>
                <div className="col-12 col-md-6 col-xxl-4 text-start">
                  <label htmlFor="category_id" className="form-label">
                    Categoría:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="category_id"
                    id="category_id"
                    onChange={this.onChange}
                    value={this.state.category_id}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    {this.state.categories.map((cat, key) => (
                      <option value={cat.id} key={key}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6 col-xxl-4 text-start my-auto">
                  <label htmlFor="image" className="form-label">
                    Imagen:
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      id="image"
                      lang="es"
                      accept="image/png, image/jpeg"
                      title="Selecciona una imagen"
                      onChange={this.onChangeFile}
                    />
                    <label htmlFor="image" className="input-group-text">
                      Examinar
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-4 text-start">
                  <div
                    className="alert alert-primary d-flex align-items-center alert-dismissible fade show"
                    role="alert"
                  >
                    <span className="me-2">
                      <i className="fas fa-info-circle fs-5"></i>
                    </span>
                    <div>
                      Al asignar un precio general, este sera mostrado como
                      precio por defecto en el punto de venta.
                    </div>
                    <button
                      type="button"
                      className="btn-close my-auto"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="form-check form-switch mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="generalPrice"
                      name="generalPrice"
                      onChange={this.onChangeGeneralPrice}
                      value={this.state.generalPrice}
                    />
                    <label className="form-check-label" htmlFor="generalPrice">
                      Asignar precio general
                    </label>
                  </div>
                  {this.state.generalPrice === true ? (
                    <div>
                      <label htmlFor="price" className="form-label">
                        Precio:
                      </label>
                      <input
                        type="number"
                        name="price"
                        id="price"
                        step="0.01"
                        min="1"
                        className="form-control mb-3"
                        placeholder="$ 0.00"
                        onChange={this.onChange}
                        value={this.state.price}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 col-md-6 col-xxl-4 text-start my-auto">
                  <label htmlFor="unit_id" className="form-label">
                    Unidades de Medida:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="unit_id"
                    id="unit_id"
                    onChange={this.onChange}
                    value={this.state.unit_id}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    {this.state.units.map((unit, key) => (
                      <option value={unit.id} key={key}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6 col-xxl-4 text-start my-auto">
                  <label htmlFor="status" className="form-label">
                    Estado:
                  </label>
                  <select
                    className="form-select mb-3"
                    name="status"
                    id="status"
                    onChange={this.onChange}
                    value={this.state.status}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="1">Activado</option>
                    <option value="0">Desactivado</option>
                  </select>
                </div>
                <div className="col-12 text-start">
                  <label htmlFor="description" className="form-label">
                    Descripción:
                  </label>
                  <textarea
                    className="form-control mb-3"
                    name="description"
                    placeholder="Descripción"
                    onChange={this.onChange}
                    value={this.state.description}
                  ></textarea>
                </div>
                <div className="col-6">
                  <Link
                    to="/products"
                    className="btn btn-outline-secondary rounded-0"
                  >
                    Cancelar
                  </Link>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-outline-success rounded-0"
                    type="submit"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <ContentDeniedMessage />
          )}
        </div>
      </div>
    );
  }
}

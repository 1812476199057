import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import withRouter from "../../HOC/withRouter";
import axios from "axios";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";
class EditUnit extends Component {
  state = {
    name: "",
    description: "",
    status: "",
    user: [],
    userId: 0,
    permissions: [],
  };

  componentDidMount() {
    const http = axios.create({
      baseURL: configUrl.baseUrl,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      withCredentials: true,
    });

    const httpUser = axios.create({
      baseURL: configUrl.baseUrl,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      withCredentials: true,
    });

    httpUser.get("/api/user").then((res) => {
      this.setState({ user: res.data });
      this.setState({ userId: res.data.id });

      //console.log("user: ", res);

      http.get(`/api/users/permissions/${this.state.userId}`).then((res) => {
        this.setState({ permissions: res.data });
      });
    });

    const id = this.props.params.id;

    //console.log(id);
    //return <h3>Hello, {props.name}</h3>;
    axios.get(`${configUrl.baseUrl}/api/units/edit/${id}`).then((res) => {
      this.setState({ name: res.data.unit.name });
      this.setState({ description: res.data.unit.description });
      this.setState({ status: res.data.unit.status });

      console.log(res.data);
    });
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    const id = this.props.params.id;
    const apiUrl = `${configUrl.baseUrl}/api/units/update/${id}`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let unit = new FormData();

    unit.append("name", this.state.name);
    unit.append("description", this.state.description);
    unit.append("status", this.state.status);

    axios
      .post(apiUrl, unit, config)
      .then(function (response) {
        if (response["status"] === 200) {
          alert("Registro actualizado exitosamente.");
          setTimeout(() => {
            window.location.href = "/units";
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });

    //console.log(this.state);
    e.preventDefault();
  };

  render() {
    return (
      <div className="View">
        <Navbar permissions={this.state.permissions} />
        <div className="container-fluid content">
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-2 px-0 d-none d-lg-block">
              <Sidebar
                userId={this.state.userId}
                permissions={this.state.permissions}
              ></Sidebar>
            </div>
            <div className="col-12 col-lg-9 col-xl-10 bg-light">
              <div className="card my-3">
                <div className="card-header">
                  <h3 className="text-start">Editar Unidad</h3>
                </div>
                <div className="card-body">
                  {this.can("edit_units") ? (
                    <form
                      onSubmit={this.onSubmit}
                      className="row justify-content-center justify-content-md-start rounded py-5 px-3"
                    >
                      <p className="text-dark mb-3 text-center">
                        Para editar registro por favor rellena los siguientes
                        campos.
                      </p>
                      <div className="col-12 col-md-6 text-start">
                        <label for="name" className="form-label">
                          Nombre:
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Nombre"
                          className="form-control mb-3"
                          onChange={this.onChange}
                          value={this.state.name}
                        />
                      </div>
                      <div className="col-12 col-md-6 text-start">
                        <label for="status" className="form-label">
                          Estado:
                        </label>
                        <select
                          className="form-select mb-3"
                          name="status"
                          id="status"
                          onChange={this.onChange}
                          value={this.state.status}
                        >
                          <option value="">Selecciona una opción</option>
                          <option value="1">Activado</option>
                          <option value="0">Desactivado</option>
                        </select>
                      </div>
                      <div className="col-12 text-start">
                        <label for="description" className="form-label">
                          Descripción:
                        </label>
                        <textarea
                          className="form-control mb-3"
                          name="description"
                          id="description"
                          placeholder="Descripción"
                          onChange={this.onChange}
                          value={this.state.description}
                        ></textarea>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-6">
                          <Link
                            to="/units"
                            className="btn btn-outline-secondary rounded-0"
                          >
                            Cancelar
                          </Link>
                        </div>
                        <div className="col-6">
                          <button
                            className="btn btn-outline-success rounded-0"
                            type="submit"
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <ContentDeniedMessage />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const hocEditUnit = withRouter(EditUnit);

export default hocEditUnit;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import configUrl from "../../../lib/config.json";
import ContentDeniedMessage from "../../../components/ContentDeniedMessage";

export default class CreateExpenseCategory extends Component {
  state = {
    name: "",
    description: "",
    parent_id: 0,
    categories: [],
    user: [],
    userId: 0,
    permissions: [],
    companyId: 0,
  };

  componentDidMount() {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const currentPermissions = localStorage.getItem("permissions");

    this.setState({ companyId: companyId });
    this.setState({ userId: userId });
    this.setState({ permissions: currentPermissions });

    const httpUrl = configUrl.uri;
    //return <h3>Hello, {props.name}</h3>;
    Axios.get(httpUrl + `/expense-categories/${companyId}`).then((res) => {
      const categories = res.data;
      this.setState({ categories });
    });
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  onSubmit = (e) => {
    const httpUrl = configUrl.uri;
    //console.log(this.state);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let product = new FormData();

    product.append("name", this.state.name);
    product.append("description", this.state.description);
    product.append("parent_id", this.state.parent_id);
    product.append("company_id", this.state.companyId);

    Axios.post(httpUrl + "/expense-categories/save", product, config)
      .then(function (response) {
        if (response["status"] === 200) {
          alert("Registro creado exitosamente.");
          setTimeout(() => {
            window.location.href = "/finance/expenses/categories";
          }, 2000);
        }
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });

    e.preventDefault();
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div className="my-3 px-lg-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/finance">Finanzas</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/finance/expenses">Gastos</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/finance/expenses/categories">
                Categorías de Gastos
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nueva Categoría de Gastos
            </li>
          </ol>
        </nav>
        <div className="row mb-3">
          <div className="col-12 my-auto">
            <h3 className="text-center text-md-start mb-3 mb-md-0">
              Nueva Categoría de Gastos
            </h3>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            {this.can("create_expense_categories") ? (
              <form
                onSubmit={this.onSubmit}
                className="row justify-content-center rounded py-5 px-3"
              >
                <p className="text-dark mb-3 text-center">
                  Para crear un nuevo registro por favor rellena los siguientes
                  campos.
                </p>
                <div className="col-12 col-md-6 text-start">
                  <label htmlFor="name" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Nombre"
                    className="form-control mb-3"
                    onChange={this.onChange}
                    value={this.state.name}
                  />
                </div>
                <div className="col-12 col-md-6 text-start mb-3">
                  <label htmlFor="parent_id" className="form-label">
                    Categoría Padre:
                  </label>
                  <select
                    className="form-select"
                    name="parent_id"
                    id="parent_id"
                    onChange={this.onChange}
                    value={this.state.parent_id}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    <option value="0">Ninguno</option>
                    {this.state.categories.map((cat, key) => (
                      <option value={cat.id} key={key}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 text-start">
                  <label htmlFor="description" className="form-label">
                    Descripción:
                  </label>
                  <textarea
                    className="form-control mb-3"
                    name="description"
                    id="description"
                    placeholder="Descripción"
                    onChange={this.onChange}
                    value={this.state.description}
                  ></textarea>
                </div>
                <div className="col-6 text-center">
                  <Link
                    to="/finance/expenses/categories"
                    className="btn btn-outline-secondary rounded-0"
                  >
                    Cancelar
                  </Link>
                </div>
                <div className="col-6 text-center">
                  <button
                    className="btn btn-outline-success rounded-0"
                    type="submit"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            ) : (
              <ContentDeniedMessage />
            )}
          </div>
        </div>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { LongDateTimeFormat } from "../../components/FormatDate";
import Pagination from "../../components/Pagination";
import config from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

function InventoryIndex() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const [permissions, setPermissions] = useState([]);

  const httpUrl = config.uri;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = records.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setIsLoading(true);

    setUserId(await uId);
    setPermissions(await currentPermissions);
    const res = await Axios.get(httpUrl + `/inventory/${uId}`);
    setRecords(await res.data.inventory);
    //console.log(res);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteRecord = (e, id = null) => {
    if (id) {
      console.log(id);
      Axios.delete(httpUrl + `/inventory/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            getData();
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  return (
    <div className="my-3">
      <div className="row mb-3">
        <div className="col-12 col-sm-6 my-auto text-center text-sm-start">
          <h2>Inventario</h2>
        </div>
        <div className="col-12 col-sm-6 my-auto text-center text-sm-end">
          {can("add_inventories") ? (
            <Link
              to="/inventory/new"
              className="btn btn-outline-primary rounded-0"
            >
              Nuevo Inventario <i className="fas fa-plus"></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="card my-3">
        {can("inventories_index") ? (
          <div className="card-body">
            {records && records.length > 0 ? (
              [
                isLoading ? (
                  <div className="alert alert-primary text-center" role="alert">
                    Cargando...
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover align-middle">
                      <thead>
                        <tr>
                          <th className="text-center">ID</th>
                          <th className="text-center">Producto</th>
                          <th className="text-center">Cantidad</th>
                          <th className="text-center">Sucursal</th>
                          <th
                            className="text-center"
                            style={{ minWidth: "100px" }}
                          >
                            Opciones
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, key) => (
                          <tr key={key}>
                            <td>{item.id}</td>
                            <td>{item.product.name}</td>
                            <td>{item.quantity}</td>
                            <td>{item.branch.name}</td>
                            <td style={{ minWidth: "100px" }}>
                              {can("edit_inventories") ? (
                                <Link
                                  to={`/inventory/edit/${item.id}`}
                                  className="btn btn-primary btn-sm rounded-0 mx-1"
                                  title={`Editar Registro`}
                                >
                                  <i className="fas fa-pen"></i>
                                </Link>
                              ) : (
                                ""
                              )}
                              {can("delete_inventories") ? (
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm rounded-0 mx-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#confirmRecordDeletion-${item.id}`}
                                  title={`Eliminar Registro`}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              ) : (
                                ""
                              )}
                              <div
                                className="modal fade"
                                id={`confirmRecordDeletion-${item.id}`}
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex="-1"
                                aria-labelledby="confirmRecordDeletionLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="confirmRecordDeletionLabel"
                                      >
                                        Atención!
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        <div className="col-12">
                                          <p className="text-center text-danger fw-bold">
                                            ¿Esta seguro de eliminar este
                                            registro?
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <p className="text-center mb-0">
                                          <b>Producto:</b>
                                        </p>
                                        <p className="text-center">
                                          {item.product.name}
                                        </p>
                                      </div>
                                      <div className="col-12">
                                        <p className="text-center mb-0">
                                          <b>Cantidad:</b>
                                        </p>
                                        <p className="text-center">
                                          {item.quantity}
                                        </p>
                                      </div>
                                      <div className="col-12">
                                        <p className="text-center mb-0">
                                          <b>Sucursal:</b>
                                        </p>
                                        <p className="text-center">
                                          {item.branch.name}
                                        </p>
                                      </div>
                                      <div className="col-12">
                                        <p className="text-center mb-0">
                                          <b>Creado:</b>
                                        </p>
                                        <p className="text-center">
                                          <LongDateTimeFormat
                                            date={item.created_at}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary rounded-0"
                                        data-bs-dismiss="modal"
                                      >
                                        Cancelar
                                      </button>
                                      {can("delete_inventories") ? (
                                        <form
                                          onSubmit={(e) =>
                                            deleteRecord(e, item.id)
                                          }
                                        >
                                          <input
                                            type="hidden"
                                            name="id"
                                            value={item.id}
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-outline-danger rounded-0"
                                            data-bs-dismiss="modal"
                                          >
                                            Confirmar
                                          </button>
                                        </form>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ),
              ]
            ) : (
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <strong>
                  Necesitas agregar un nuevo registro para ver el listado en
                  esta sección!
                </strong>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}

            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={records.length}
              paginate={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </div>
        ) : (
          <div className="card-body">
            <ContentDeniedMessage />
          </div>
        )}
      </div>
    </div>
  );
}

export default InventoryIndex;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../HOC/withRouter";
import Axios from "axios";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";
import Pagination from "../../components/Pagination";
import { LongDateTimeFormat } from "../../components/FormatDate";
import CurrencyFormat from "../../components/CurrencyFormat";
import RecordNotFound from "../../components/Messages/RecordNotFound";

class EditProduct extends Component {
  state = {
    name: "",
    image: null,
    currentImage: null,
    description: "",
    price: "",
    useGeneralPrice: 0,
    status: "",
    category_id: "",
    unit_id: "",
    company_id: 0,
    companySlug: "",
    categories: [],
    units: [],
    userId: 0,
    permissions: [],
    generalPrice: false,
    productVariants: [],
    loading: false,
    currentPage: 1,
    itemsPerPage: 10,
    recordFound: false,
  };

  componentDidMount() {
    const id = this.props.params.id;
    const companyId = localStorage.getItem("companyId");

    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    this.setState({ userId: uId });
    this.setState({ permissions: currentPermissions });

    const httpUrl = configUrl.uri;

    Axios.get(httpUrl + `/v2/companies/${companyId}/products/edit/${id}`).then(
      (res) => {
        res.status === 200
          ? this.setState({ recordFound: true })
          : this.setState({ recordFound: false });
        //console.log("Edit Data:", res);
        this.setState({ name: res.data.product.name });
        this.setState({ currentImage: res.data.product.image });
        this.setState({ description: res.data.product.description });
        if (res.data.product.price) {
          this.setState({ price: res.data.product.price });
        } else {
          this.setState({ price: "" });
        }
        this.setState({ status: res.data.product.status });
        this.setState({ category_id: res.data.product.category_id });
        this.setState({ companySlug: res.data.company.slug });
        this.setState({ unit_id: res.data.product.unit_id });
        this.setState({ company_id: res.data.product.company_id });
        this.setState({ categories: res.data.categories });
        this.setState({ units: res.data.units });
        this.setState({
          useGeneralPrice: parseInt(res.data.product.use_general_price),
        });

        if (parseInt(res.data.product.use_general_price) === 1) {
          //console.log(this.state.price);
          this.setState({ generalPrice: true });

          document.getElementById("generalPrice").setAttribute("checked", "");
        }
        this.setState({ productVariants: res.data.productVariants });
      }
    );
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  onSubmit = (e) => {
    const httpUrl = configUrl.uri;
    const id = this.props.params.id;
    const apiUrl = httpUrl + `/products/update/${id}`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let product = new FormData();

    product.append("name", this.state.name);
    if (this.state.image !== null) {
      product.append("image", this.state.image);
    }
    product.append("description", this.state.description);
    product.append("price", this.state.price);
    product.append("use_general_price", this.state.useGeneralPrice);
    product.append("status", this.state.status);
    product.append("category_id", this.state.category_id);
    product.append("unit_id", this.state.unit_id);
    product.append("company_id", this.state.company_id);

    Axios.post(apiUrl, product, config)
      .then(function (response) {
        if (response["status"] === 200) {
          alert("Producto actualizado exitosamente.");
          setTimeout(() => {
            window.location.href = "/products";
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });

    //console.log(this.state);
    e.preventDefault();
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeGeneralPrice = (e) => {
    var isChecked = e.target.checked;
    this.setState({
      [e.target.name]: e.target.checked,
    });
    if (isChecked === false) {
      this.setState({ useGeneralPrice: 0 });
      this.setState({ price: "" });
    } else {
      this.setState({ useGeneralPrice: 1 });
    }
  };

  onChangeFile = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  deleteRecord = (e, id = null) => {
    const httpUrl = configUrl.uri;
    if (id) {
      //console.log(id);
      Axios.delete(httpUrl + `/product-variant/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            this.componentDidMount();
            alert("Registro eliminado correctamente");
            //console.log(res);
          }
        })
        .catch((error) => {
          //console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  render() {
    const { productVariants, loading, currentPage, itemsPerPage } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = productVariants.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const paginate = (pageNum) => this.setState({ currentPage: pageNum });
    const nextPage = () => this.setState({ currentPage: currentPage + 1 });
    const prevPage = () => this.setState({ currentPage: currentPage - 1 });

    const imageStyles = {
      maxWidth: "175px",
    };

    return (
      <div className="mt-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/products">Productos</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Editar Producto
            </li>
          </ol>
        </nav>
        <div className="row mb-3">
          <div className="col-12 col-sm-6 my-auto">
            <h3 className="text-center text-sm-start mb-3 mb-sm-0">
              Editar Producto
            </h3>
          </div>
        </div>
        <div className="card mt-3 mb-5 border-0 shadow">
          {this.can("edit_products") ? (
            <div className="card-body">
              {this.state.recordFound ? (
                <form
                  onSubmit={this.onSubmit}
                  className="row justify-content-center rounded py-5 px-3"
                >
                  <p className="text-dark mb-3">
                    Para editar un registro por favor cambia el valor de los
                    siguientes campos.
                  </p>
                  <div className="col-12 col-lg-6 text-start">
                    <label htmlFor="name" className="form-label">
                      Nombre:
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Nombre"
                      className="form-control mb-3"
                      onChange={this.onChange}
                      value={this.state.name}
                    />
                  </div>
                  <div className="col-12 col-lg-6 text-start">
                    <label htmlFor="category_id" className="form-label">
                      Categoría:
                    </label>
                    <select
                      className="form-select mb-3"
                      name="category_id"
                      id="category_id"
                      onChange={this.onChange}
                      value={this.state.category_id}
                    >
                      <option value="" disabled>
                        Selecciona una opción
                      </option>
                      {this.state.categories.map((cat, key) => (
                        <option value={cat.id} key={key}>
                          {cat.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-lg-8 text-start my-md-auto">
                    <label htmlFor="image" className="form-label">
                      Imagen:
                    </label>
                    <div className="input-group mb-4 mb-md-3">
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        id="image"
                        lang="es"
                        accept="image/png, image/jpeg, image/jpg"
                        title="Selecciona una imagen"
                        onChange={this.onChangeFile}
                      />
                      <label htmlFor="image" className="input-group-text">
                        Examinar
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 my-md-auto">
                    <img
                      className="img-fluid mb-4 mb-md-3"
                      src={this.state.currentImage}
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-lg-4 text-start">
                    <div
                      className="alert alert-primary d-flex align-items-center alert-dismissible fade show"
                      role="alert"
                    >
                      <span className="me-2">
                        <i className="fas fa-info-circle fs-5"></i>
                      </span>
                      <div>
                        Al asignar un precio general, este sera mostrado como
                        precio por defecto en el punto de venta.
                      </div>
                      <button
                        type="button"
                        className="btn-close my-auto"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="generalPrice"
                        name="generalPrice"
                        onChange={this.onChangeGeneralPrice}
                        checked={this.state.generalPrice}
                        value={this.state.useGeneralPrice}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="generalPrice"
                      >
                        Asignar precio general
                      </label>
                    </div>

                    {this.state.generalPrice === true ? (
                      <div>
                        <label htmlFor="price" className="form-label">
                          Precio:
                        </label>
                        <input
                          type="number"
                          name="price"
                          id="price"
                          step="0.01"
                          min="1"
                          className="form-control mb-3"
                          placeholder="$ 0.00"
                          onChange={this.onChange}
                          value={this.state.price}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-12 col-lg-4 text-start">
                    <label htmlFor="unit_id" className="form-label">
                      Unidades de Medida:
                    </label>
                    <select
                      className="form-select mb-3"
                      name="unit_id"
                      id="unit_id"
                      onChange={this.onChange}
                      value={this.state.unit_id}
                    >
                      <option value="" disabled>
                        Selecciona una opción
                      </option>
                      {this.state.units.map((unit, key) => (
                        <option value={unit.id} key={key}>
                          {unit.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-lg-4 text-start">
                    <label htmlFor="status" className="form-label">
                      Estado:
                    </label>
                    <select
                      className="form-select mb-3"
                      name="status"
                      id="status"
                      onChange={this.onChange}
                      value={this.state.status}
                    >
                      <option value="">Selecciona una opción</option>
                      <option value="1">Activado</option>
                      <option value="0">Desactivado</option>
                    </select>
                  </div>
                  <div className="col-12 text-start">
                    <label htmlFor="description" className="form-label">
                      Descripción:
                    </label>
                    <textarea
                      className="form-control mb-3"
                      name="description"
                      placeholder="Descripción"
                      onChange={this.onChange}
                      value={this.state.description}
                    ></textarea>
                  </div>
                  <div className="col-6">
                    <Link
                      to="/products"
                      className="btn btn-outline-secondary rounded-0"
                    >
                      Cancelar
                    </Link>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-outline-success rounded-0"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              ) : (
                <RecordNotFound />
              )}
            </div>
          ) : (
            <ContentDeniedMessage />
          )}
        </div>

        {this.state.recordFound ? (
          <div className="mb-5">
            <div className="row mb-4">
              <div className="col-12 col-md-6 my-auto text-md-start">
                <h3 className="text-start">Variantes del Producto</h3>
              </div>
              <div className="col-12 col-md-6 my-auto text-md-end">
                <Link
                  to={`/products/create-variant/${this.props.params.id}`}
                  className="btn btn-outline-primary rounded-0"
                >
                  Crear Variante <i className="fas fa-plus"></i>
                </Link>
              </div>
            </div>
            <div className="card my-3 border-0 shadow">
              {this.can("edit_products") ? (
                <div className="card-body">
                  {loading ? (
                    <div className="alert alert-primary" role="alert">
                      Cargando...
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Imagen</th>
                            <th className="text-center">Precio</th>
                            <th className="text-center">Tamaño</th>
                            <th className="text-center">Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((variant, key) => (
                            <tr key={key}>
                              <td>{variant.id}</td>
                              <td>{variant.name}</td>
                              <td>
                                <img
                                  src={
                                    configUrl.baseUrl +
                                    `/storage/companies/${this.state.companySlug}/products/variants/images/${variant.image}`
                                  }
                                  alt=""
                                  className="img-fluid"
                                  style={imageStyles}
                                />
                              </td>
                              <td>
                                <CurrencyFormat price={variant.price} />
                              </td>
                              <td>{variant.size.abbreviation}</td>
                              <td style={{ minWidth: "100px" }}>
                                {this.can("edit_products") ? (
                                  <Link
                                    to={`/products/edit-variant/${variant.id}`}
                                    className="btn btn-primary btn-sm rounded-0 mx-1"
                                    title={`Editar ${variant.name}`}
                                  >
                                    <i className="fas fa-pen"></i>
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {this.can("delete_products") ? (
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm rounded-0 mx-1"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#confirmRecordDeletion-${variant.id}`}
                                    title={`Eliminar ${variant.name}`}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="modal fade"
                                  id={`confirmRecordDeletion-${variant.id}`}
                                  data-bs-backdrop="static"
                                  data-bs-keyboard="false"
                                  tabIndex="-1"
                                  aria-labelledby="confirmRecordDeletionLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="confirmRecordDeletionLabel"
                                        >
                                          Atención!
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="row">
                                          <div className="col-12">
                                            <p className="text-center text-danger fw-bold">
                                              ¿Esta seguro de eliminar este
                                              registro?
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <p className="text-center mb-0">
                                            <b>Nombre:</b>
                                          </p>
                                          <p className="text-center">
                                            {variant.name}
                                          </p>
                                        </div>

                                        <div className="col-12">
                                          <p className="text-center mb-0">
                                            <b>Tamaño:</b>
                                          </p>
                                          <p className="text-center">
                                            {variant.size.abbreviation}
                                          </p>
                                        </div>

                                        <div className="col-12">
                                          <p className="text-center mb-0">
                                            <b>Precio:</b>
                                          </p>
                                          <p className="text-center">
                                            <CurrencyFormat
                                              price={variant.price}
                                            />
                                          </p>
                                        </div>

                                        <div className="col-12">
                                          <p className="text-center mb-0">
                                            <b>Agregado:</b>
                                          </p>
                                          <p className="text-center">
                                            <LongDateTimeFormat
                                              date={variant.created_at}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="modal-footer justify-content-between">
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary rounded-0"
                                          data-bs-dismiss="modal"
                                        >
                                          Cancelar
                                        </button>
                                        {this.can("delete_products") ? (
                                          <form
                                            onSubmit={(e) =>
                                              this.deleteRecord(e, variant.id)
                                            }
                                          >
                                            <input
                                              type="hidden"
                                              name="id"
                                              value={variant.id}
                                            />
                                            <button
                                              type="submit"
                                              className="btn btn-outline-danger rounded-0"
                                              data-bs-dismiss="modal"
                                            >
                                              Confirmar
                                            </button>
                                          </form>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={productVariants.length}
                    paginate={paginate}
                    nextPage={nextPage}
                    prevPage={prevPage}
                  />
                </div>
              ) : (
                <div className="card-body">
                  <ContentDeniedMessage />
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const hocEditProduct = withRouter(EditProduct);

export default hocEditProduct;

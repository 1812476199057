import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { LongDateTimeFormat } from "../../components/FormatDate";
import Pagination from "../../components/Pagination";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

export default class Customers extends Component {
  state = {
    customers: [],
    loading: false,
    currentPage: 1,
    itemsPerPage: 10,
    userId: 0,
    permissions: [],
  };

  componentDidMount() {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    this.setState({ userId: uId });
    this.setState({ permissions: currentPermissions });
    this.getData(uId);
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  getData = async (userId) => {
    const httpUrl = configUrl.uri;

    this.setState({ loading: true });

    const res = await Axios.get(httpUrl + `/customers/${userId}`);

    this.setState(await { customers: res.data });

    //console.log(res);
    this.setState({ loading: false });
  };

  deleteRecord = (e, id = null) => {
    const httpUrl = configUrl.uri;
    if (id) {
      //console.log(id);
      Axios.delete(httpUrl + `/customers/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            this.componentDidMount();
            alert("Registro eliminado correctamente");
            //console.log(res);
          }
        })
        .catch((error) => {
          //console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  render() {
    const { customers, loading, currentPage, itemsPerPage } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = customers.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNum) => this.setState({ currentPage: pageNum });
    const nextPage = () => this.setState({ currentPage: currentPage + 1 });
    const prevPage = () => this.setState({ currentPage: currentPage - 1 });

    return (
      <div className="card my-3">
        <div className="card-header">
          <div className="row">
            <div className="col-12 col-md-6 my-auto text-md-start">
              <h2>Clientes</h2>
            </div>
            <div className="col-12 col-md-6 my-auto text-md-end">
              {this.can("add_customers") ? (
                <Link
                  to="/customers/create"
                  className="btn btn-outline-primary rounded-0"
                >
                  Agregar Cliente <i className="fas fa-plus"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {this.can("customers_index") ? (
          <div className="card-body">
            {loading ? (
              <div className="alert alert-primary" role="alert">
                Cargando...
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-center">Nombre</th>
                      <th className="text-center">Telefono</th>
                      <th className="text-center">Correo</th>
                      <th className="text-center">Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((customer, key) => (
                      <tr key={key}>
                        <td>{customer.id}</td>
                        <td>{customer.name}</td>
                        <td>{customer.phone}</td>
                        <td>{customer.email}</td>
                        <td style={{ minWidth: "100px" }}>
                          {this.can("edit_customers") ? (
                            <Link
                              to={`/customers/edit/${customer.id}`}
                              className="btn btn-primary btn-sm rounded-0 mx-1"
                              title={`Editar ${customer.name}`}
                            >
                              <i className="fas fa-pen"></i>
                            </Link>
                          ) : (
                            ""
                          )}
                          {this.can("delete_customers") ? (
                            <button
                              type="button"
                              className="btn btn-danger btn-sm rounded-0 mx-1"
                              data-bs-toggle="modal"
                              data-bs-target={`#confirmRecordDeletion-${customer.id}`}
                              title={`Eliminar ${customer.name}`}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          <div
                            className="modal fade"
                            id={`confirmRecordDeletion-${customer.id}`}
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="confirmRecordDeletionLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="confirmRecordDeletionLabel"
                                  >
                                    Atención!
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-12">
                                      <p className="text-center text-danger fw-bold">
                                        ¿Esta seguro de eliminar este registro?
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <p className="text-center mb-0">
                                      <b>Nombre:</b>
                                    </p>
                                    <p className="text-center">
                                      {customer.name}
                                    </p>
                                  </div>
                                  <div className="col-12">
                                    <p className="text-center mb-0">
                                      <b>Agregado:</b>
                                    </p>
                                    <p className="text-center">
                                      <LongDateTimeFormat date={customer.created_at} />
                                    </p>
                                  </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary rounded-0"
                                    data-bs-dismiss="modal"
                                  >
                                    Cancelar
                                  </button>
                                  {this.can("delete_customers") ? (
                                    <form
                                      onSubmit={(e) =>
                                        this.deleteRecord(e, customer.id)
                                      }
                                    >
                                      <input
                                        type="hidden"
                                        name="id"
                                        value={customer.id}
                                      />
                                      <button
                                        type="submit"
                                        className="btn btn-outline-danger rounded-0"
                                        data-bs-dismiss="modal"
                                      >
                                        Confirmar
                                      </button>
                                    </form>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={customers.length}
              paginate={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </div>
        ) : (
          <div className="card-body">
            <ContentDeniedMessage />
          </div>
        )}
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import config from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";

function EditUser() {
  const [company, setCompany] = useState([]);
  const [branches, setBranches] = useState([]);
  const [roles, setRoles] = useState([]);
  const [branchId, setBranchId] = useState();
  const [roleId, setRoleId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const httpUrl = config.uri;

  //Record ID
  const { id } = useParams();

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  const getData = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);

    const apiUrl = httpUrl + `/users/edit/${id}`;
    Axios.get(apiUrl).then((res) => {
      //console.log("Edit user data: ", res);
      setCompany(res.data.companyUser);
      setBranches(res.data.branches);
      setRoles(res.data.roles);
      setName(res.data.user.uname);
      setEmail(res.data.user.email);
      setBranchId(res.data.user.branch_id);
      setRoleId(res.data.currentRole.id);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const updateRecord = async (e) => {
    const apiUrl = httpUrl + `/users/update/${id}`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let data = new FormData();
    data.append("name", name);
    data.append("email", email);
    if (password) {
      data.append("password", password);
    }
    data.append("company_id", company.company_id);
    data.append("branch_id", branchId);
    data.append("role_id", roleId);

    /*if(password === undefined || password === null || password === ''){
      const userdata = {
        name: name,
        email: email,
        company_id: company.company_id,
        branch_id: branchId,
        role_id: roleId,
      };
      console.log(userdata);

    }else {
      const userdata = {
        name: name,
        email: email,
        password: password,
        company_id: company.company_id,
        branch_id: branchId,
        role_id: roleId,
      };
      console.log(userdata);
    }

    const userdata = {
      name: name,
      email: email,
      password: password,
      company_id: company.company_id,
      branch_id: branchId,
      role_id: roleId,
    };
    console.log(userdata);*/

    //Sending data to the api to save the record
    Axios.post(apiUrl, data, config)
      .then(function (res) {
        console.log(res);
        if (res["status"] === 200) {
          alert("Registro actualizado exitosamente.");
          setTimeout(() => {
            window.location.href = "/users";
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Hubo un problema al intentar procesar la solicitud.");
      });
  };

  return (
    <div className="card my-3">
      <div className="card-header">
        <h3 className="text-start">Editar Usuario</h3>
      </div>
      <div className="card-body">
        {can("edit_users") ? (
          <form className="row justify-content-center rounded py-5 px-3">
            <p className="text-dark mb-3 text-center">
              Cambie el valor de un campo y despues haga click en guardar para
              actualizar el registro.
            </p>
            <div className="col-12 text-start mb-3">
              <label htmlFor="name" className="form-label">
                Nombre:
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Ej: John Doe"
                className="form-control mb-3"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="col-12 col-lg-6 text-start mb-3">
              <label htmlFor="branch_id" className="form-label">
                Sucursal:
              </label>
              <select
                className="form-select"
                name="branch_id"
                id="branch_id"
                onChange={(e) => setBranchId(parseInt(e.target.value))}
                value={branchId}
              >
                <option value="0">Selecciona una opción</option>
                {branches.map((item, key) => (
                  <option value={item.id} key={key}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-lg-6 text-start mb-3">
              <label htmlFor="role_id" className="form-label">
                Roles:
              </label>
              <select
                className="form-select"
                name="role_id"
                id="role_id"
                onChange={(e) => setRoleId(e.target.value)}
                value={roleId}
              >
                <option value="0">Selecciona una opción</option>
                {roles.map((item, key) => (
                  <option value={item.id} key={key}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-lg-6 text-start mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Ej: correo@correo.com"
                className="form-control mb-3"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="col-12 col-lg-6 text-start mb-5">
              <label htmlFor="password" className="form-label">
                Contraseña:
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="**********"
                className="form-control mb-3"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="col-6">
              <Link to="/users" className="btn btn-outline-secondary rounded-0">
                Cancelar
              </Link>
            </div>
            <div className="col-6">
              <button
                className="btn btn-outline-success rounded-0"
                type="button"
                onClick={(e) => updateRecord(e)}
              >
                Guardar
              </button>
            </div>
          </form>
        ) : (
          <ContentDeniedMessage />
        )}
      </div>
    </div>
  );
}

export default EditUser;

import React, { Component } from "react";
import Axios from "axios";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import { LongDateTimeFormat } from "../../components/FormatDate";
import CurrencyFormat from "../../components/CurrencyFormat";
import configUrl from "../../lib/config.json";
import ContentDeniedMessage from "../../components/ContentDeniedMessage";
import SaleStatus from "../../components/SaleStatus";
import CardStatus from "../../components/CardStatus";
import AlertMessage from "../../components/AlertMessage";

export default class Sales extends Component {
  state = {
    sales: [],
    loading: false,
    currentPage: 1,
    itemsPerPage: 10,
    userId: 0,
    permissions: [],
    pendingSales: 0,
    salesInProgress: 0,
    completedSales: 0,
    canceledSales: 0,
    allSales: 0,
  };

  componentDidMount() {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");
    this.getData(uId);
    this.setState({ userId: uId });
    this.setState({ permissions: currentPermissions });
  }

  can = (permission) => {
    const permissions = this.state.permissions;
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  getData = async (userId) => {
    const httpUrl = configUrl.uri;

    this.setState({ loading: true });

    const res = await Axios.get(httpUrl + `/sales/${userId}`);

    this.setState(await { sales: res.data });
    this.setState(await { allSales: res.data });

    console.log("Sales:", res);

    //Filter sales by status

    let pendingSales = this.state.sales.filter((sale) => sale.status === 1);
    let salesInProgress = this.state.sales.filter((sale) => sale.status === 2);
    let completedSales = this.state.sales.filter((sale) => sale.status === 3);
    let canceledSales = this.state.sales.filter((sale) => sale.status === 10);

    this.setState({ pendingSales: pendingSales });
    this.setState({ salesInProgress: salesInProgress });
    this.setState({ completedSales: completedSales });
    this.setState({ canceledSales: canceledSales });

    this.setState({ loading: false });
  };

  deleteRecord = (e, id = null) => {
    const httpUrl = configUrl.uri;
    if (id) {
      console.log(id);
      Axios.delete(httpUrl + `/sales/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            this.componentDidMount();
            alert("Registro eliminado correctamente");
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  displaySalesByStatus = (e, sales) => {
    this.setState({ sales: sales });
    e.preventDefault();
  };

  render() {
    const { sales, loading, currentPage, itemsPerPage } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sales.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNum) => this.setState({ currentPage: pageNum });
    const nextPage = () => this.setState({ currentPage: currentPage + 1 });
    const prevPage = () => this.setState({ currentPage: currentPage - 1 });
    return (
      <div className="my-3">
        <div className="row mb-3">
          <div className="col-12 col-md-6 my-auto">
            <h2 className="text-center text-md-start mb-3 mb-md-0">Ventas</h2>
          </div>
          <div className="col-12 col-md-6 my-auto text-center text-md-end">
            {this.can("sale_reports") ? (
              <Link
                to="/sales/reports"
                className="btn btn-outline-danger rounded-0 ms-2 ms-md-0 me-2 me-md-4 mb-3 mb-sm-0"
              >
                Reportes <i className="fas fa-chart-line"></i>
              </Link>
            ) : (
              ""
            )}
            {this.can("generate_sales") ? (
              <Link
                to="/sales/generate"
                className="btn btn-outline-primary rounded-0 ms-2 ms-md-0 me-2 me-md-0 mb-3 mb-sm-0"
              >
                Generar Nueva Venta <i className="fas fa-plus"></i>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <div
              className={`card shadow border-0 text-secondary`}
              role="button"
              onClick={(e) => this.displaySalesByStatus(e, this.state.allSales)}
            >
              <div className="card-body text-center">
                <h6 className="card-title">Totales</h6>
                <p className="btn bg-transparent fs-3 text-secondary text-center mb-0">
                  {this.state.allSales.length}
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <CardStatus
              status={1}
              function={(e) =>
                this.displaySalesByStatus(e, this.state.pendingSales)
              }
            >
              <p className="btn bg-transparent fs-3 text-white text-center mb-0">
                {this.state.pendingSales.length}
              </p>
            </CardStatus>
          </div>
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <CardStatus
              status={2}
              function={(e) =>
                this.displaySalesByStatus(e, this.state.salesInProgress)
              }
            >
              <p className="btn bg-transparent fs-3 text-white text-center mb-0">
                {this.state.salesInProgress.length}
              </p>
            </CardStatus>
          </div>
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <CardStatus
              status={3}
              function={(e) =>
                this.displaySalesByStatus(e, this.state.completedSales)
              }
            >
              <p className="btn bg-transparent fs-3 text-white text-center mb-0">
                {this.state.completedSales.length}
              </p>
            </CardStatus>
          </div>
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <CardStatus
              status={10}
              function={(e) =>
                this.displaySalesByStatus(e, this.state.canceledSales)
              }
            >
              <p className="btn bg-transparent fs-3 text-white text-center mb-0">
                {this.state.canceledSales.length}
              </p>
            </CardStatus>
          </div>
        </div>
        <div className="card border-0 shadow">
          {this.can("sales_index") ? (
            <div className="card-body">
              {loading ? (
                <div className="alert alert-primary text-center" role="alert">
                  Cargando...
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Cliente</th>
                        <th className="text-center">Monto Total</th>
                        <th className="text-center">Estado</th>
                        <th className="text-center">Fecha</th>
                        <th className="text-center">Opciones</th>
                      </tr>
                    </thead>
                    {currentItems.length > 0 ? (
                      <tbody>
                        {currentItems.map((sale, key) => (
                          <tr key={key}>
                            <td>{sale.id}</td>
                            <td>
                              {sale["customer"] &&
                              sale["temporary_customer"] === "No especificado"
                                ? sale["customer"].name
                                : sale["temporary_customer"]}
                            </td>
                            <td>
                              <CurrencyFormat price={sale.total_amount} />
                            </td>
                            <td className="text-center">
                              <SaleStatus status={parseInt(sale.status)} />
                            </td>
                            <td>
                              <LongDateTimeFormat date={sale.created_at} />
                            </td>
                            <td style={{ minWidth: "100px" }}>
                              {this.can("edit_sales") ? (
                                <Link
                                  to={`/sales/edit/${sale.id}`}
                                  className="btn btn-primary btn-sm rounded-0 mx-1"
                                  title={`Editar ${sale.name}`}
                                >
                                  <i className="fas fa-pen"></i>
                                </Link>
                              ) : (
                                ""
                              )}
                              {this.can("delete_sales") ? (
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm rounded-0 mx-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#confirmRecordDeletion-${sale.id}`}
                                  title={`Eliminar ${sale.name}`}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              ) : (
                                ""
                              )}
                              <div
                                className="modal fade"
                                id={`confirmRecordDeletion-${sale.id}`}
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex="-1"
                                aria-labelledby="confirmRecordDeletionLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="confirmRecordDeletionLabel"
                                      >
                                        Atención!
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        <div className="col-12">
                                          <p className="text-center text-danger fw-bold">
                                            ¿Esta seguro de eliminar este
                                            registro?
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <p className="text-center mb-0">
                                          <b>Monto total de venta:</b>
                                        </p>
                                        <p className="text-center">
                                          <CurrencyFormat
                                            price={sale.total_amount}
                                          />
                                        </p>
                                      </div>
                                      <div className="col-12">
                                        <p className="text-center mb-0">
                                          <b>Agregado:</b>
                                        </p>
                                        <p className="text-center">
                                          <LongDateTimeFormat date={sale.created_at} />
                                        </p>
                                      </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary rounded-0"
                                        data-bs-dismiss="modal"
                                      >
                                        Cancelar
                                      </button>
                                      {this.can("delete_sales") ? (
                                        <form
                                          onSubmit={(e) =>
                                            this.deleteRecord(e, sale.id)
                                          }
                                        >
                                          <input
                                            type="hidden"
                                            name="id"
                                            value={sale.id}
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-outline-danger rounded-0"
                                            data-bs-dismiss="modal"
                                          >
                                            Confirmar
                                          </button>
                                        </form>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={"6"}>
                            <AlertMessage
                              type={`warning`}
                              message={`No se encotraron registros de ventas.`}
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={sales.length}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
              />
            </div>
          ) : (
            <div className="card-body">
              <ContentDeniedMessage />
            </div>
          )}
        </div>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { LongDateTimeFormat } from "../../components/FormatDate";
import Pagination from "../../components/Pagination";
import CurrencyFormat from "../../components/CurrencyFormat";
import config from "../../lib/config.json";

function ProductIndex() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [companyUser, setCompanyUser] = useState();
  const [userId, setUserId] = useState();
  const [permissions, setPermissions] = useState([]);
  const httpUrl = config.uri;
  const imageStyles = {
    maxWidth: "175px",
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = records.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const getUser = async () => {
    const uId = parseInt(localStorage.getItem("userId"));
    const currentPermissions = localStorage.getItem("permissions");

    setUserId(await uId);
    setPermissions(await currentPermissions);
    fetchRecords(await uId);
  };

  useEffect(() => {
    getUser();
  }, []);

  const deleteRecord = (e, id = null) => {
    if (id) {
      //console.log(id);
      Axios.delete(httpUrl + `/products/delete/${id}`)
        .then((res) => {
          if (res["status"] === 200) {
            fetchRecords(userId);
          }
        })
        .catch((error) => {
          //console.log(error);
          alert("Hubo un problema al procesar la solicitud.");
        });
    }
    id = null;

    e.preventDefault();
  };

  const fetchRecords = async (userId) => {
    setIsLoading(true);
    const res = await Axios.get(httpUrl + `/products/${userId}`);
    setRecords(await res.data.products);
    setCompanyUser(await res.data.companyUser.company);
    //console.log(res);
    setIsLoading(false);
  };

  const can = (permission) => {
    if (permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="card my-3">
      <div className="card-header">
        <div className="row">
          <div className="col-12 col-md-6 my-auto text-md-start">
            <h2>Productos</h2>
          </div>
          <div className="col-12 col-md-6 my-auto text-md-end">
            {can("create_products") ? (
              <Link
                to="/products/create"
                className="btn btn-outline-primary rounded-0"
              >
                Crear Producto <i className="fas fa-plus"></i>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {can("products_index") ? (
        <div className="card-body">
          {isLoading ? (
            <div className="alert alert-primary" role="alert">
              Cargando...
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">Nombre</th>
                    <th className="text-center">Imagen</th>
                    <th className="text-center">Descripción</th>
                    <th className="text-center">Precio</th>
                    <th className="text-center">Categoría</th>
                    <th className="text-center">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((product, key) => (
                    <tr key={key}>
                      <td>{product.id}</td>
                      <td>{product.name}</td>
                      <td>
                        <img
                          src={
                            config.baseUrl +
                            `/storage/companies/${companyUser.slug}/products/images/${product.image}`
                          }
                          alt=""
                          className="img-fluid"
                          style={imageStyles}
                        />
                      </td>
                      <td>{product.description}</td>
                      <td>
                        {parseInt(product.use_general_price) === 1 ? <CurrencyFormat price={ product.price } />: 'Precio General no asignado.'}
                      </td>
                      <td>{product.category.name}</td>
                      <td style={{ minWidth: "100px" }}>
                        {can("edit_products") ? (
                          <Link
                            to={`/products/edit/${product.id}`}
                            className="btn btn-primary btn-sm rounded-0 mx-1"
                            title={`Editar ${product.name}`}
                          >
                            <i className="fas fa-pen"></i>
                          </Link>
                        ) : (
                          ""
                        )}
                        {can("delete_products") ? (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm rounded-0 mx-1"
                            data-bs-toggle="modal"
                            data-bs-target={`#confirmRecordDeletion-${product.id}`}
                            title={`Eliminar ${product.name}`}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        <div
                          className="modal fade"
                          id={`confirmRecordDeletion-${product.id}`}
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          tabIndex="-1"
                          aria-labelledby="confirmRecordDeletionLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="confirmRecordDeletionLabel"
                                >
                                  Atención!
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-12">
                                    <p className="text-danger fw-bold">
                                      ¿Esta seguro de eliminar este registro?
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <p className="mb-0">
                                    <b>Nombre:</b>
                                  </p>
                                  <p>{product.name}</p>
                                </div>
                                <div className="col-12">
                                  <p className="mb-0">
                                    <b>Precio:</b>
                                  </p>
                                  <p>{product.price}</p>
                                </div>
                                <div className="col-12">
                                  <p className="mb-0">
                                    <b>Creado:</b>
                                  </p>
                                  <p>
                                    <LongDateTimeFormat date={product.created_at} />
                                  </p>
                                </div>
                              </div>
                              <div className="modal-footer justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary rounded-0"
                                  data-bs-dismiss="modal"
                                >
                                  Cancelar
                                </button>
                                {can("delete_products") ? (
                                  <form
                                    onSubmit={(e) =>
                                      deleteRecord(e, product.id)
                                    }
                                  >
                                    <input
                                      type="hidden"
                                      name="id"
                                      value={product.id}
                                    />
                                    <button
                                      type="submit"
                                      className="btn btn-outline-danger rounded-0"
                                      data-bs-dismiss="modal"
                                    >
                                      Confirmar
                                    </button>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={records.length}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        </div>
      ) : (
        <div className="card-body">
          <div className="alert alert-danger" role="alert">
            Parece que no tienes acceso para entrar a esta página.
          </div>
        </div>
      )}
    </div>
  );
}
export default ProductIndex;

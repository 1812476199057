import React from "react";

const MenuCategoryItem = (props) => {

  const activateItem = (id) => {
    //Getting elements
    const items = document.querySelectorAll(".menu-category-item");

    //Iterating elements to remove active class
    items.forEach((item) => {
      item.classList.remove("active");
    });

    //Activating current elements.
    let currentCategory = document.querySelector(`#menuItem${id}`);
    currentCategory.classList.add('active');
  };

  return (
    <div
      className={`card mb-3 shadow rounded-3 menu-category-item  ${props.class ? props.class : ''}`}
      onClick={() => {
        props.function();
        activateItem(props.id);
      }}
      id={`menuItem${props.id}`}
    >
      <div className="card-body item-content">
        <p className="text-center item-name">{props.name}</p>
      </div>
    </div>
  );
};

export default MenuCategoryItem;
